import { getDefaultHotKeys } from "@/utils/keyboard/hotkeys";
import { getPlatform, Platform } from "@/utils/platform";
import { TableConfig, TableKey } from "../table/Table.model";

export interface UserPreferences {
  hotKeys: Partial<{ [key in Platform]: { [key in string]: string[] } }>;
  smallScreenDisclaimerSeen: boolean;
  tableConfig: { [key in TableKey]: TableConfig } | null;
}

export const getDefaultUserPreferences = (): UserPreferences => ({
  hotKeys: {
    [getPlatform()]: getDefaultHotKeys(),
  },
  smallScreenDisclaimerSeen: false,
  tableConfig: null,
});
