import Vue from 'vue';
import Vuex from 'vuex';

import { AppStore } from './app/App.store';
import { DataModelStore } from './dataModel/DataModel.store';
import { ProcessStore } from './process/Process.store';
import { UIStore } from './ui/UI.store';
import { UserStore } from './user/User.store';
import { DocumentStore } from './document/Document.store';
import { SignalRStore } from './signalR/signalR.store';
import { ResourceStore } from './resource/Resource.store';
import { FormStore } from './form/Form.store';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    app: AppStore,
    dataModel: DataModelStore,
    processes: ProcessStore,
    user: UserStore,
    ui: UIStore,
    signalR: SignalRStore,
    resource: ResourceStore,
    document: DocumentStore,
    form: FormStore
  },
});
