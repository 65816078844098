<template>
  <div class="pup-c-fullscreen-input">
    <pds-input
      v-if="!isFullScreen"
      :label="label"
      :value="value"
      :required="required"
      :status="status"
      :placeholder="placeholder"
      :disabled="disabled"
      :readonly="readonly"
      :variation="variation"
      :ignoreLabelFormatting="ignoreLabelFormatting"
      :tooltip="tooltip"
      :type="type"
      :isTextOnBlur="isTextOnBlur"
      ref="input"
      @focus="$emit('focus', $event)"
      @keydown.native="keyDownHandler"
      @update-input="updateInput"
    />
    <pup-editor
      v-else
      :value="value"
      fullScreen
      :fullScreenTitle="fullScreenTitle"
      :hasDataModelSelector="false"
      :readonly="readonly"
      :disabled="disabled"
      @update-input="updateInput"
    />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Emit, Model, Prop, Watch } from "vue-property-decorator";
import { InputComponent, PdsTypes } from "@procesio/procesio-design-system";
import { HotKeys, isHotKeyMatched } from "@/utils/keyboard/hotkeys";
import { mapGetters } from "vuex";
import { KeyboardCode } from "@/utils/keyboard";
import { ActionTypes as UIActionTypes } from "@/store/ui/UI.actions";
import { EventBus, Events } from "@/utils/eventBus";

@Component({
  components: {
    "pds-input": InputComponent,
  },
  model: {
    event: "update-input",
  },
  computed: {
    ...mapGetters({
      keyboardPressedCodes: "keyboardPressedCodes",
    }),
  },
})
export default class FullscreenInputComponent extends Vue {
  @Model("update-input") value!: string;

  @Prop({ default: "" }) label!: string;

  @Prop({ default: "" }) placeholder!: string;

  @Prop({ default: false, type: Boolean }) disabled!: boolean;

  @Prop({ default: false, type: Boolean }) readonly!: boolean;

  @Prop({ default: "text" }) type!: string;

  @Prop({ default: PdsTypes.InputVariation.NORMAL })
  variation!: PdsTypes.InputVariation;

  @Prop({ default: false, type: Boolean }) required!: boolean;

  @Prop({ default: false, type: Boolean }) ignoreLabelFormatting!: boolean;

  @Prop({ default: "" }) tooltip!: string;

  @Prop({ default: false, type: Boolean }) isTextOnBlur!: boolean;

  @Prop({ default: "" }) fullScreenTitle!: string;

  @Prop({
    default: () => {
      return { type: "", message: "" };
    },
  })
  status!: PdsTypes.InputStatus;

  isFullScreen = false;

  mounted() {
    EventBus.$on(Events["INPUT:FULLSCREEN_TOGGLE"], this.onFullScreenToggle);
  }

  beforeDestoy() {
    EventBus.$off(Events["INPUT:FULLSCREEN_TOGGLE"], this.onFullScreenToggle);
  }

  @Watch("isFullScreen")
  onFullScreenStateUpdate(isFullScreen: boolean) {
    if (!isFullScreen) {
      this.$nextTick(() => {
        const inputComponent = this.$refs.input as Vue;
        inputComponent && inputComponent.focused();
        const inputsTags = inputComponent?.$el?.getElementsByTagName(
          "input"
        );
        if (inputsTags && inputsTags.length) {
          inputsTags[0].value = '';
          inputsTags[0].value = this.value;
        }
      });
    }
  }

  keyDownHandler(event: KeyboardEvent) {
    // record keyboard codes because default listener handles body's element event
    if (event.key.toLowerCase() === KeyboardCode.ESC) {
      this.$store.dispatch(
        UIActionTypes.ADD_PRESSED_KEYBOARD_CODE,
        KeyboardCode.ESC
      );
      event.preventDefault();
      event.stopPropagation();
    }

    if (
      !this.isFullScreen &&
      isHotKeyMatched(HotKeys.ENLARGE_INPUT, this.keyboardPressedCodes)
    ) {
      this.toggleFullScreen(true);
    } else if (
      this.isFullScreen &&
      isHotKeyMatched(HotKeys.COLLAPSE_INPUT, this.keyboardPressedCodes)
    ) {
      this.toggleFullScreen(false);
    }
  }

  toggleFullScreen(isFullScreen: boolean) {
    this.isFullScreen = isFullScreen;
  }

  onFullScreenToggle(isFullScreen: boolean) {
    if (this.isFullScreen && !isFullScreen) {
      this.toggleFullScreen(false);
    }
  }

  @Emit("update-input")
  updateInput(value: string) {
    return value;
  }
}
</script>

<style lang="scss">
@import "./FullscreenInput.component.scss";
</style>
