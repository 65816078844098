import { BannerType, BannerUnit } from "@/components/Banner/banner.model";
import { Commit, Dispatch } from "vuex";
import { MutationTypes } from "./UI.mutations";
import ls from "@/services/localStorage";
import { Loader, LsMessageKey } from "@/store/ui/UI.store";
import { createGuid } from "@/utils/type/guid";
import { KeyboardCode } from "@/utils/keyboard";
import { getPlatform } from "@/utils/platform";
import { geAnnouncementData } from "@/services/announcements";
import { getCampaignData } from "@/services/campaign";
import UserPreferencesService from "@/services/application/userPreferences/UserPreferences.service";
import {
  getDefaultUserPreferences,
  UserPreferences,
} from "@/services/application/userPreferences/UserPreferences.model";
import { cloneDeep } from "lodash";
import {
  TableConfig,
  TableKey,
} from "@/services/application/table/Table.model";

export enum ActionTypes {
  SET_LOADING_STATE = "SET_LOADING_STATE",
  SET_LOADER = "SET_LOADER",
  CLEAR_LOADER = "CLEAR_LOADER",
  SET_DISABLED_STATE = "SET_DISABLED_STATE",
  SET_BANNER_TYPE = "SET_BANNER_TYPE",
  LOAD_ANNOUNCEMENTS_DATA = "LOAD_ANNOUNCEMENTS_DATA",
  LOAD_CAMPAIGN_DATA = "LOAD_CAMPAIGN_DATA",
  ADD_PRESSED_KEYBOARD_CODE = "ADD_PRESSED_KEYBOARD_CODE",
  DELETE_PRESSED_KEYBOARD_CODE = "DELETE_PRESSED_KEYBOARD_CODE",
  CLEAR_PRESSED_KEYBOARD_CODES = "CLEAR_PRESSED_KEYBOARD_CODES",
  SET_HOTKEYS = "SET_HOTKEYS",
  LOAD_USER_PREFERENCES = "LOAD_USER_PREFERENCES",
  SET_USER_PREFERENCES = "SET_USER_PREFERENCES",
  SET_TABLE_CONFIG = "SET_TABLE_CONFIG",
  SET_TABLE_CONFIG_BY_TABLE_KEY = "SET_TABLE_CONFIG_BY_TABLE_KEY",
}

export interface Actions {
  [ActionTypes.SET_LOADING_STATE](
    { commit }: { commit: Commit },
    payload: boolean
  ): void;

  [ActionTypes.SET_LOADER]({ commit }: { commit: Commit }): Loader;

  [ActionTypes.CLEAR_LOADER](
    { commit }: { commit: Commit },
    payload: Loader
  ): void;

  [ActionTypes.SET_DISABLED_STATE](
    { commit }: { commit: Commit },
    payload: boolean
  ): void;

  [ActionTypes.SET_BANNER_TYPE](
    { commit }: { commit: Commit },
    payload: {
      key: BannerUnit;
      value: BannerType | string[] | null;
    }
  ): void;

  [ActionTypes.LOAD_ANNOUNCEMENTS_DATA]({
    commit,
    getters,
  }: {
    commit: Commit;
    getters: any;
  }): void;

  [ActionTypes.LOAD_CAMPAIGN_DATA]({
    commit,
    getters,
  }: {
    commit: Commit;
    getters: any;
  }): void;

  [ActionTypes.ADD_PRESSED_KEYBOARD_CODE](
    { commit, getters }: { commit: Commit; getters: any },
    payload: string
  ): void;

  [ActionTypes.DELETE_PRESSED_KEYBOARD_CODE](
    { commit, getters }: { commit: Commit; getters: any },
    payload: string
  ): void;

  [ActionTypes.CLEAR_PRESSED_KEYBOARD_CODES]({
    commit,
  }: {
    commit: Commit;
  }): void;

  [ActionTypes.SET_HOTKEYS](
    { dispatch }: { dispatch: Dispatch },
    payload: { [key in string]: string[] }
  ): void;

  [ActionTypes.LOAD_USER_PREFERENCES]({
    dispatch,
  }: {
    dispatch: Dispatch;
  }): void;

  [ActionTypes.SET_USER_PREFERENCES](
    { commit, getters }: { commit: Commit; getters: any },
    payload: Partial<UserPreferences>
  ): void;

  [ActionTypes.SET_TABLE_CONFIG](
    { dispatch, getters }: { dispatch: Dispatch; getters: any },
    payload: { [key in TableKey]: TableConfig } | null
  ): void;

  [ActionTypes.SET_TABLE_CONFIG_BY_TABLE_KEY](
    { dispatch, getters }: { dispatch: Dispatch; getters: any },
    payload: { tableKey: TableKey; config: TableConfig }
  ): void;
}

export const actions: any = {
  [ActionTypes.SET_LOADING_STATE](
    { commit }: { commit: Commit },
    isLoading: boolean
  ) {
    commit(MutationTypes.SET_LOADING_STATE, isLoading);
  },

  [ActionTypes.SET_LOADER]({ commit }: { commit: Commit }) {
    const loader: Loader = {
      id: createGuid(),
    };

    commit(MutationTypes.SET_LOADER, loader);

    return loader;
  },

  [ActionTypes.CLEAR_LOADER]({ commit }: { commit: Commit }, loader: Loader) {
    commit(MutationTypes.CLEAR_LOADER, loader);
  },

  [ActionTypes.SET_DISABLED_STATE](
    { commit }: { commit: Commit },
    isDisabled: boolean
  ) {
    commit(MutationTypes.SET_DISABLED_STATE, isDisabled);
  },

  [ActionTypes.SET_BANNER_TYPE](
    { commit, getters }: { commit: Commit; getters: any },
    payload: {
      key: BannerUnit;
      value: BannerType | string[] | null;
    }
  ) {
    const messages = ls.get(LsMessageKey) || {};
    const workspaceMessages =
      messages[getters.activeWorkspaceIdStringified] || {};

    // Check if banner hasn't been dismissed already in localStorage
    if (payload.value) {
      const dismissed = workspaceMessages[payload.key] === payload.value;
      if (dismissed) {
        commit(MutationTypes.SET_BANNER_TYPE, {
          key: payload.key,
          value: null,
        });
        return;
      }
    } else {
      messages[getters.activeWorkspaceIdStringified] = {
        ...workspaceMessages,
        [payload.key]: getters.bannerTypes[payload.key],
      };
      ls.set(LsMessageKey, messages);
    }

    commit(MutationTypes.SET_BANNER_TYPE, payload);
  },

  async [ActionTypes.LOAD_ANNOUNCEMENTS_DATA]({ commit }: { commit: Commit }) {
    const data = await geAnnouncementData();
    commit(MutationTypes.SET_ANNOUNCEMENTS_DATA, data);
  },

  async [ActionTypes.LOAD_CAMPAIGN_DATA]({ commit }: { commit: Commit }) {
    const data = await getCampaignData();
    commit(MutationTypes.SET_CAMPAIGN_DATA, data);
  },

  [ActionTypes.ADD_PRESSED_KEYBOARD_CODE](
    { commit, getters }: { commit: Commit; getters: any },
    code: string
  ) {
    if (code === null) {
      return;
    }

    code = code.toLowerCase();

    if (!Object.values(KeyboardCode).includes(code)) {
      return;
    }

    const pressedCodes = [...getters.keyboardPressedCodes];

    if (!pressedCodes.includes(code)) {
      pressedCodes.push(code);
    }

    commit(MutationTypes.SET_PRESSED_KEYBOARD_CODES, pressedCodes);
  },

  [ActionTypes.DELETE_PRESSED_KEYBOARD_CODE](
    { commit, getters }: { commit: Commit; getters: any },
    code: string
  ) {
    if (code === null) {
      return;
    }

    code = code.toLowerCase();
    const pressedCodes = [...getters.keyboardPressedCodes];
    const index = pressedCodes.indexOf(code);
    if (index === -1) {
      return;
    }

    pressedCodes.splice(index, 1);

    commit(MutationTypes.SET_PRESSED_KEYBOARD_CODES, pressedCodes);
  },

  [ActionTypes.CLEAR_PRESSED_KEYBOARD_CODES]({ commit }: { commit: Commit }) {
    commit(MutationTypes.SET_PRESSED_KEYBOARD_CODES, []);
  },

  [ActionTypes.SET_HOTKEYS](
    { dispatch, getters }: { dispatch: Dispatch; getters: any },
    hotKeys: { [key in string]: string[] }
  ) {
    let preferences = cloneDeep(getters.userPreferences) as UserPreferences;
    if (!preferences) {
      preferences = {} as any;
    }

    if (!preferences?.hotKeys) {
      preferences.hotKeys = {} as any;
    }

    const platform = getPlatform();

    preferences.hotKeys[platform] = {
      ...(preferences.hotKeys[platform] || {}),
      ...hotKeys,
    };

    dispatch(ActionTypes.SET_USER_PREFERENCES, preferences);
  },

  [ActionTypes.SET_USER_PREFERENCES](
    { commit, getters }: { commit: Commit; getters: any },
    preferences: Partial<UserPreferences>
  ) {
    const defaultPreferences = getDefaultUserPreferences();
    if (!preferences || typeof preferences !== "object") {
      preferences = defaultPreferences;
    }

    preferences = {
      ...defaultPreferences,
      ...preferences,
    };

    if (
      JSON.stringify(preferences) !== JSON.stringify(getters.userPreferences)
    ) {
      UserPreferencesService.saveUserPreferences(
        preferences as UserPreferences
      );
    }

    commit(MutationTypes.SET_USER_PREFERENCES, preferences);
  },

  async [ActionTypes.LOAD_USER_PREFERENCES]({
    dispatch,
  }: {
    dispatch: Dispatch;
  }) {
    const data = await UserPreferencesService.getUserPreferences();
    await dispatch(ActionTypes.SET_USER_PREFERENCES, data.content);
  },

  [ActionTypes.SET_TABLE_CONFIG](
    { dispatch, getters }: { dispatch: Dispatch; getters: any },
    tableConfig: { [key in TableKey]: TableConfig } | null
  ) {
    let preferences = cloneDeep(getters.userPreferences) as UserPreferences;
    if (!preferences) {
      preferences = {} as any;
    }

    preferences.tableConfig = tableConfig || null;

    dispatch(ActionTypes.SET_USER_PREFERENCES, preferences);
  },

  [ActionTypes.SET_TABLE_CONFIG_BY_TABLE_KEY](
    { dispatch, getters }: { dispatch: Dispatch; getters: any },
    { tableKey, config }: { tableKey: TableKey; config: TableConfig }
  ) {
    let tableConfig = { ...getters.tableConfig };

    if (tableConfig) {
      tableConfig[tableKey] = config;
    } else {
      tableConfig = {
        [tableKey]: config,
      };
    }

    dispatch(ActionTypes.SET_TABLE_CONFIG, tableConfig);
  },
};
