import { RouteConfig } from "vue-router";

const Layout = () =>
  import("@/modules/ResourceMap/presentation/ResourceMap.layout.vue");

const ResourceMap = () =>
  import(
    "@/modules/ResourceMap/presentation/pages/View/ResourceMap.component.vue"
  );

const ResourceMapTopbar = () =>
  import(
    "@/modules/ResourceMap/presentation/components/Topbar/ResourceMapTopbar.component.vue"
  );

export enum RouteName {
  RESOURCE_MAP = "Resource Map",
}

export const RoutePath = "resource-map";

export const routes: RouteConfig = {
  path: RoutePath,
  component: Layout,
  name: RouteName.RESOURCE_MAP,
  meta: { title: RouteName.RESOURCE_MAP },
  children: [
    {
      path: "/",
      component: ResourceMap,
      name: RouteName.RESOURCE_MAP,
      meta: {
        topbar: ResourceMapTopbar,
      },
    },
  ],
};
