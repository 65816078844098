import { actions } from "./Form.actions";
import { mutations } from "./Form.mutations";
import getters from "./Form.getters";
import {
  FormBuilderMode,
  FormBuilderUserRole,
} from "@procesio/procesio-ui-builder";
import { FormTemplateStatus } from "@/services/form/FormTemplate.model";

export const FormStore = {
  namespaced: true,
  state: () => ({
    id: null,
    name: "",
    mode: FormBuilderMode.FILL,
    status: FormTemplateStatus.DRAFT,
    users: [],
    isActive: true,
    isSaved: false,
    hideBrading: false,
  }),
  mutations,
  actions,
  getters,
};

export interface FormState {
  id: string | null;
  name: string;
  mode: FormBuilderMode;
  status: FormTemplateStatus;
  users: {
    id: string;
    name: string;
    roles: [FormBuilderUserRole];
  }[];
  isActive: boolean;
  isSaved: boolean;
  hideBrading: boolean;
}
