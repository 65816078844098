var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"container",class:[
    'pup-c-editor--container',
    _vm.isFullScreen && 'pup-c-editor--container--full-screen',
  ]},[(!_vm.isFullScreen)?_c('pds-control-label',{attrs:{"label":_vm.label,"tooltip":_vm.tooltip,"required":_vm.required,"ignoreFormatting":_vm.ignoreLabelFormatting}}):_vm._e(),_vm._v(" "),(_vm.isFullScreen)?_c('div',{key:"full-screen",staticClass:"pup-c-editor--full-screen-slot"},[_c('div',{staticClass:"pup-c-editor--header"},[_c('span',{staticClass:"pup-c-editor--header--title"},[_vm._v("\n        "+_vm._s(_vm.fullScreenTitle ||
            (_vm.parent ? _vm.parent.name : "") + (_vm.label ? " | " + _vm.label : ""))+"\n      ")]),_vm._v(" "),_c('pds-icon',{staticClass:"pup-c-editor--header--close",attrs:{"icon":"close"},on:{"click":function($event){return _vm.toggleFullScreen(false)}}})],1),_vm._v(" "),_vm._t("fullScreenEditorPrepend")],2):_vm._e(),_vm._v(" "),_c('div',{ref:"editor",class:[
      'pup-c-editor',
      _vm.disabled ? 'pup-c-editor--disabled' : '',
      _vm.isFullScreen ? 'pup-c-editor--full-screen' : '',
    ],on:{"keydown":_vm.onEditorKeyDown}}),_vm._v(" "),(_vm.messageText)?_c('pds-message',{class:[
      'pup-c-editor--info pds-u-p--r--12 pds-u-m--t--8',
      _vm.isFullScreen && 'pds-u-m--l--24 pds-u-m--b--12',
    ],attrs:{"size":"small","icon":"info","variation":"ghost","text":_vm.messageText}}):_vm._e(),_vm._v(" "),(!_vm.isFullScreen)?_c('pds-validation-message',{attrs:{"status":_vm.status},on:{"statusClassChanged":(e) => (_vm.statusClass = e)}}):_vm._e(),_vm._v(" "),(_vm.isDataModelSelectorVisible && !_vm.disabled)?_c('pup-data-model-selector',{class:[
      'pup-c-input-data-model-selector--acordeon',
      'pup-c-input-data-model-selector--acordeon--right',
    ],style:({
      top: `${_vm.editorOffsetTop}px`,
    }),attrs:{"parentId":_vm.parent ? _vm.parent.parentId : null,"dataModelList":[],"parentDataModelId":_vm.parentDataModelId,"parentVariableId":_vm.parentVariableId,"processVariables":_vm.processVariables,"expectedDataModelId":_vm.settings ? _vm.settings.dataTypeId : null,"isListExpected":_vm.settings ? _vm.settings.isList : false},on:{"closed":_vm.closeDataModelSelector,"pickElement":_vm.selectVariable}}):_vm._e(),_vm._v(" "),(typeof _vm.isInputValid === 'boolean')?_c('pds-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      content: _vm.isInputValid ? 'Valid' : 'Invalid',
      boundariesElement: 'window',
    }),expression:"{\n      content: isInputValid ? 'Valid' : 'Invalid',\n      boundariesElement: 'window',\n    }"}],class:[
      'pup-c-input-group--validity-status--editor',
      'pup-c-input-group--validity-status--' +
        (_vm.isInputValid ? 'valid' : 'invalid'),
    ],style:({
      top: `${_vm.editorOffsetTop}px`,
      right: `${_vm.editorMinimapWidth + 12}px`,
    }),attrs:{"icon":_vm.isInputValid ? 'check' : 'clear',"isOutlined":true,"size":"tiny"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }