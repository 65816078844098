import Vue from "vue";
import { Component } from "vue-property-decorator";

import { Variable } from "@/services/crud/Orchestration.service";
import {
  DataModel,
  DataModelAttribute,
} from "@/services/datamodel/DataModel.model";
import { getVariableList } from "../../Values/ValueDataTypesHelper";

@Component
export class VariableParser extends Vue {
  parseVariableIdToName(
    value: string,
    withDelimiter = false,
    extraVariables: Variable[] = [],
    scopes: string[] = []
  ) {
    const ids = value.split(".");

    const variable = getVariableList(extraVariables, scopes).find(
      (val: Variable) => val && val.id === ids[0] && !val.isDeleted
    );

    if (!variable) {
      return value;
    }

    const variableType: DataModel = this.$store.getters.dataTypes.find(
      (dataType: DataModel) => variable.dataType === dataType.id
    );

    let attributeType: DataModel;
    let names = "";

    ids.slice(1).forEach((attributeId, key) => {
      let attribute: DataModelAttribute | undefined;

      if (key === 0) {
        attribute = variableType.attributes?.find(
          (attr) => attr.id === attributeId
        );
      } else {
        attribute = attributeType?.attributes?.find(
          (attr) => attr.id === attributeId
        );
      }

      if (names) {
        names += ".";
      }
      names += `${attribute ? attribute.name : attributeId}`;

      const nextAttributeDataType = this.$store.getters.dataTypes.find(
        (type: DataModel) => type.id === attribute?.dataTypeId
      );

      if (nextAttributeDataType) {
        attributeType = nextAttributeDataType;
      }
    });

    // add dot after variable name if name is defined and attributes exist,
    // special case for webhook custom response variables
    return `${withDelimiter ? "<%" : ""}${variable.name}${
      variable.name && names ? "." : ""
    }${names}${withDelimiter ? "%>" : ""}`;
  }

  parseVariableNameToId(
    value: string,
    extraVariables: Variable[] = [],
    scopes: string[] = []
  ) {
    const parsedValue = value.replace(
      /([<%]{2})([^%>]+)([%>]{2})/gm,
      match => {
        const variableWithoutQuotes = match.replace("<%", "").replace("%>", "");

        const variableTreeName = variableWithoutQuotes.split(".");
        const variables = getVariableList(extraVariables, scopes).filter(
          (variable) => !variable.isDeleted
        );
        const hasVariableWithEmptyName =
          variables.findIndex((variable) => !variable.name) !== -1;

        const variable = variables.find(
          (val: Variable) =>
            (val && val.name === variableTreeName[0]) ||
            // special case to handle variable with empty name: e.g. webhook custom response
            (hasVariableWithEmptyName && !val.name)
        );

        const variableType: DataModel = this.$store.getters.dataTypes.find(
          (dataType: DataModel) => variable?.dataType === dataType.id
        );

        if (!variableType) {
          return match;
        }

        // special case to handle variable with empty name: e.g. webhook custom response
        if (
          !variable?.name &&
          hasVariableWithEmptyName &&
          !!variableType.attributes.length
        ) {
          variableTreeName.unshift("");
        }

        let attributeType: DataModel;
        let ids = "";
        let missingAttributeName = "";

        variableTreeName.slice(1).forEach((attrName, key) => {
          const attributeName = missingAttributeName + attrName;
          const type = key === 0 ? variableType : attributeType;
          // TODO: normalize usage of name vs display name
          const attribute:
            | DataModelAttribute
            | undefined = type?.attributes?.find(
            (attr) =>
              attr.name === attributeName || attr.displayName === attributeName
          );

          if (attribute) {
            attributeType = attribute;
            missingAttributeName = "";
            ids += `.${attribute.id}`;
          } else {
            attributeType = attributeType || variableType;
            missingAttributeName += `${attrName}.`;
          }
        });

        if (!variable?.name && !ids) {
          return match;
        }

        return `${variable?.id}${ids}`;
      }
    );

    return parsedValue;
  }

  parseVarTreeToGuids(
    variableTree: Array<{
      attribute: string;
      id: string;
    }>
  ) {
    // Reduce the array to a string of form guid.guid...
    // Ex: 8ED75018-95EC-40CC-9527-3CFE21855DB8.0F9154C1-903C-45EA-8DF2-59B87ECEC5FC
    return (
      variableTree
        .reduceRight((acc, variable) => {
          return `${variable.id}.${acc}`;
        }, "")
        // reduce function adds an unncesary dot which we have to remove
        // regex removes the last char of a string
        .replace(/.$/, "")
    );
  }
}
