import { isValidJSON } from "@/utils/type/json";
import { isValidEmail, splitEmails } from "@/utils/value/email";
import { isValidArray } from "@/utils/type/array";
import { FormControl } from "./FormControl";
import { ValidationError, ValidatorFn } from "./Models";
import { isValidURL } from "@/utils/value/url";
import {
  TableControlValue,
} from "@/modules/CredentialManager/components/Form/Controls.model";

function isEmptyInputValue(value: any) {
  // we don't check for string here so it also works with arrays and boolean
  return (
    value == null ||
    value.length === 0 ||
    value === false ||
    value === undefined
  );
}

function hasValidLength(value: any): boolean {
  // non-strict comparison is intentional, to check for both `null` and `undefined` values
  return value != null && typeof value.length === "number";
}

/**
 * A regular expression that matches valid url addresses.
 */

const URL_REGEXP = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

const IP_REGEXP = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
const DNS_REGEXP = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/;

/**
 * A regular expression that matches non-numeric and non-alphabetical characters
 *
 * It is used to validate entities names which should not contain these symbols.
 *
 * A variable should not contain any special characters or blank spaces.
 * The only exception is the “_” character.
 * Examples of valid variable names are: `report_date`, `reportDate`, `reportdate`.
 * Invalid variable names: `report.date`, `report@date`, `report!date`.
 */
const SPECIAL_CHARACTERS = /[`!$%^&*()+\-=\[\]{};'"\\|,.<>\/~]/;

export class Validators {
  /**
   * @description
   * Validator that requires the control have a non-empty value.
   *
   * @usageNotes
   *
   *  ### Validate that the field value is non-empty
   *
   * ```typescript
   * const control = new FormControl('', Validators.required);
   *
   * control.validate();
   *
   * console.log(control.errors); // ['This field is required.']
   *
   * ```
   *
   * @returns An error message `This field is required.`
   * if the validation check fails, otherwise `null`.
   */
  static required(control: FormControl): ValidationError | null {
    return isEmptyInputValue(control.value) ? "This field is required." : null;
  }

  // custom validation for table component
  static requiredTable(control: FormControl): ValidationError | null {
    const rows = (control.value as TableControlValue).rows;
    const foundUnfinished = rows.find((row) => row.value && !row.key);
    return foundUnfinished ? "This field is required" : null;
  }

  /**
   * @description
   * Validator that requires the control's value be true. This validator is commonly
   * used for required checkboxes.
   *
   * @usageNotes
   *
   *  ### Validate that the field value is true
   *
   * ```typescript
   * const control = new FormControl(false, Validators.requiredTrue);
   *
   * control.validate();
   *
   * console.log(control.errors); // ['This field is required.']
   * ```
   *
   * @returns An error message `This field is required.`
   * if the validation check fails, otherwise `null`.
   */
  static requiredTrue(control: FormControl): ValidationError | null {
    return control.value === true ? null : "This field is required.";
  }

  /**
   * @description
   * Validator that requires the control's value to be greater than or equal to the provided number.
   *
   * @usageNotes
   *
   * ### Validate against a minimum of 3
   *
   * ```typescript
   * const control = new FormControl(2, Validators.min(3));
   *
   * control.validate();
   *
   * console.log(control.errors); // ['Field value should be bigger than 3']
   * ```
   *
   * @returns A validator function that returns an message `Field value should be
   * bigger than min` if the validation check fails, otherwise `null`.
   */
  static min(min: number): ValidatorFn {
    return (control: FormControl): ValidationError | null => {
      if (isEmptyInputValue(control.value) || isEmptyInputValue(min)) {
        return null; // don't validate empty values to allow optional controls
      }

      const value = parseFloat(control.value as string);

      return !isNaN(value) && value < min
        ? `Field value must be bigger than ${min}`
        : null;
    };
  }

  /**
   * @description
   * Validator that requires the control's value to be less than or equal to the provided number.
   *
   * @usageNotes
   *
   * ### Validate against a maximum of 15
   *
   * ```typescript
   * const control = new FormControl(16, Validators.max(15));
   *
   * control.validate();
   *
   * console.log(control.errors); // ['Field value must be smaller than 15']
   * ```
   *
   * @returns A validator function that returns an message `Field value should be
   * smaller than max` if the validation check fails, otherwise `null`.
   */
  static max(max: number): ValidatorFn {
    return (control: FormControl): ValidationError | null => {
      if (isEmptyInputValue(control.value) || isEmptyInputValue(max)) {
        return null; // don't validate empty values to allow optional controls
      }
      const value = parseFloat(control.value as string);
      // Controls with NaN values after parsing should be treated as not having a
      // maximum, per the HTML forms spec: https://www.w3.org/TR/html5/forms.html#attr-input-max
      return !isNaN(value) && value > max
        ? `Field value must be smaller than ${max}`
        : null;
    };
  }

  static checked(control: FormControl): ValidationError | null {
    return !control.value ? `Right operator is not a list` : null;
  }

  static notchecked(control: FormControl): ValidationError | null {
    return control.value ? `Left cannot be a list` : null;
  }

  static url(control: FormControl): ValidationError | null {
    if (isEmptyInputValue(control.value)) {
      return null; // don't validate empty values to allow optional controls
    }

    const test =
      new RegExp(URL_REGEXP).test(control.value as string) &&
      isValidURL(control.value as string);

    return test ? null : "Not a valid URL";
  }

  /**
   * @description
   * Validator that requires the control's value pass an email validation test.
   *
   * Tests the value using a [regular
   * expression](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions)
   * pattern suitable for common usecases.
   *
   * @usageNotes
   *
   * ### Validate that the field matches a valid email pattern
   *
   * ```typescript
   * const control = new FormControl('bad@', Validators.email);
   *
   * control.validate()
   *
   * console.log(control.errors); // ['Not a valid email']
   * ```
   *
   * @returns An error message `Not a valid email`
   * if the validation check fails, otherwise `null`.
   */
  static email(control: FormControl): ValidationError | null {
    if (isEmptyInputValue(control.value)) {
      return null; // don't validate empty values to allow optional controls
    }

    return isValidEmail(control.value as string) ? null : "Not a valid email";
  }

  /**
   * @description
   * Validator that requires the control's value pass an email list validation test. Email list should be presented with delimiters allowed
   *
   * Tests the value using a [regular
   * expression](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions)
   * pattern suitable for common usecases.
   *
   * @usageNotes
   *
   * ### Validate that the field matches a valid email pattern splitted by delimiter
   *
   * ```typescript
   * const control = new FormControl('bad@;test@com', Validators.emails);
   *
   * control.validate()
   *
   * console.log(control.errors); // ['Email of index 1, 2 is not valid']
   * ```
   *
   * @returns An error message `Email of index N is not valid`
   * if the validation check fails, otherwise `null`.
   */
  static emails(control: FormControl): ValidationError | null {
    if (isEmptyInputValue(control.value)) {
      return null; // don't validate empty values to allow optional controls
    }

    const invalidEmailIndexes = splitEmails(control.value as string).reduce(
      (indexes: number[], email: string, index: number) => {
        if (!isValidEmail(email)) {
          indexes.push(++index);
        }
        return indexes;
      },
      []
    );

    if (invalidEmailIndexes.length === 0) {
      return null;
    }

    if (invalidEmailIndexes.length === 1 && invalidEmailIndexes[0] === 1) {
      return "Not a valid email";
    }

    return "Email of index " + invalidEmailIndexes.join(", ") + " is not valid";
  }

  static server(control: FormControl): ValidationError | null {
    if (isEmptyInputValue(control.value)) {
      return null;
    }

    return DNS_REGEXP.test(control.value as string) ||
      IP_REGEXP.test(control.value as string)
      ? null
      : "Not a valid server";
  }

  /**
   * @description
   * Validator that requires the length of the control's value to be greater than or equal
   * to the provided minimum length. Note that the `minLength` validator is intended to be used
   * only for types that have a numeric `length` property, such as strings or arrays. The
   * `minLength` validator logic is also not invoked for values when their `length` property is 0
   * (for example in case of an empty string or an empty array), to support optional controls. You
   * can use the standard `required` validator if empty values should not be considered valid.
   *
   * @usageNotes
   *
   * ### Validate that the field has a minimum of 3 characters
   *
   * ```typescript
   * const control = new FormControl('ng', Validators.minLength(3));
   *
   * control.validate();
   *
   * console.log(control.errors); // ['Field length must be greater than 3']
   * ```
   *
   * @returns A validator function that returns an message `Field length must be
   * smaller than min` if the validation check fails, otherwise `null`.
   */
  static minLength(minLength: number): ValidatorFn {
    return (control: FormControl): ValidationError | null => {
      if (isEmptyInputValue(control.value) || !hasValidLength(control.value)) {
        // don't validate empty values to allow optional controls
        // don't validate values without `length` property
        return null;
      }

      return (control.value as string | Array<any>).length < minLength
        ? `Field length must be greater than ${minLength} `
        : null;
    };
  }

  /**
   * @description
   * Validator that requires the length of the control's value to be less than or equal
   * to the provided maximum length.  Note that the `maxLength` validator is intended to be used
   * only for types that have a numeric `length` property, such as strings or arrays.
   *
   * @usageNotes
   *
   * ### Validate that the field has maximum of 5 characters
   *
   * ```typescript
   * const control = new FormControl('Angular', Validators.maxLength(5));
   *
   * control.validate();
   *
   * console.log(control.errors); // ['Field length must be smaller than 5']
   * ```
   *
   * @returns A validator function that returns an message `Field length must be
   * greater than max` if the validation check fails, otherwise `null`.
   */
  static maxLength(maxLength: number): ValidatorFn {
    return (control: FormControl): ValidationError | null => {
      return hasValidLength(control.value) &&
        (control.value as string | Array<any>).length > maxLength
        ? `Error: the maximum character length allowed is ${maxLength} charaters.`
        : null;
    };
  }

  /**
   * @description
   * Validator that performs no operation.
   *
   * @see `updateValueAndValidity()`
   *
   */
  static nullValidator(): ValidationError | null {
    return null;
  }

  /**
   * @description
   * Validator that requires the control's value to match a regex pattern.
   * @usageNotes
   *
   * ### Validate that the field only contains letters or spaces
   *
   * ```typescript
   * const control = new FormControl('1', Validators.pattern('[a-zA-Z ]*'));
   *
   * control.validate();
   *
   * console.log(control.errors); // ['Field doesn't meet the pattern requirements']
   * ```
   *
   * ```html
   * <input pattern="[a-zA-Z ]*">
   * ```
   *
   * ### Pattern matching with the global or sticky flag
   *
   * `RegExp` objects created with the `g` or `y` flags that are passed into `Validators.pattern`
   * can produce different results on the same input when validations are run consecutively. This is
   * due to how the behavior of `RegExp.prototype.test` is
   * specified in [ECMA-262](https://tc39.es/ecma262/#sec-regexpbuiltinexec)
   * (`RegExp` preserves the index of the last match when the global or sticky flag is used).
   * Due to this behavior, it is recommended that when using
   * `Validators.pattern` you **do not** pass in a `RegExp` object with either the global or sticky
   * flag enabled.
   *
   * ```typescript
   * // Not recommended (since the `g` flag is used)
   * const controlOne = new FormControl('1', Validators.pattern(/foo/g));
   *
   * // Good
   * const controlTwo = new FormControl('1', Validators.pattern(/foo/));
   * ```
   *
   * @param pattern A regular expression to be used as is to test the values, or a string.
   * If a string is passed, the `^` character is prepended and the `$` character is
   * appended to the provided string (if not already present), and the resulting regular
   * expression is used to test the values.
   *
   * @returns A validator function that returns an error with the message `Field doesn't
   * meet the pattern requirements` if the validation check fails, otherwise `null`.
   */
  static pattern(pattern: string | RegExp): ValidatorFn {
    if (!pattern) return Validators.nullValidator;

    let regex: RegExp;
    let regexStr: string;

    if (typeof pattern === "string") {
      regexStr = "";

      if (pattern.charAt(0) !== "^") {
        regexStr += "^";
      }

      regexStr += pattern;

      if (pattern.charAt(pattern.length - 1) !== "$") {
        regexStr += "$";
      }

      regex = new RegExp(regexStr);
    } else {
      regexStr = pattern.toString();

      regex = pattern;
    }
    return (control: FormControl): ValidationError | null => {
      if (isEmptyInputValue(control.value)) {
        return null; // don't validate empty values to allow optional controls
      }

      const value = control.value as string;

      return regex.test(value)
        ? null
        : `Field doesn't meet the pattern requirements`;
    };
  }

  /**
   * @description
   * Validator that requires the control's value doesn't contain any special characters
   *
   * @usageNotes
   *
   * ### Validate that the field doesn't have any special characters
   *
   * ```typescript
   * const control = new FormControl('v+@', Validators.noSpecialChar);
   *
   * control.validate()
   *
   * console.log(control.errors); // ['Error: special characters are not accepted']
   * ```
   *
   * @returns An error message `Error: special characters are not accepted`
   * if the validation check fails, otherwise `null`.
   */
  static noSpecialChar(control: FormControl): ValidationError | null {
    if (isEmptyInputValue(control.value)) {
      return null; // don't validate empty values to allow optional controls
    }

    return SPECIAL_CHARACTERS.test(control.value as string)
      ? "Error: special characters are not accepted"
      : null;
  }

  /**
   * @description
   * Validator that requires the control's value doesn't contain any space
   *
   * @usageNotes
   *
   * ### Validate that the field doesn't have any space
   *
   * ```typescript
   * const control = new FormControl('v+@ ', Validators.noSpace);
   *
   * control.validate()
   *
   * console.log(control.errors); // ['Error: space is not accepted']
   * ```
   *
   * @returns An error message `Error: space is not accepted`
   * if the validation check fails, otherwise `null`.
   */
  static noSpace(control: FormControl): ValidationError | null {
    if (isEmptyInputValue(control.value)) {
      return null; // don't validate empty values to allow optional controls
    }

    return /\s/g.test(control.value as string)
      ? "Error: space is not accepted"
      : null;
  }

  /**
   * @description
   * Validator that requires the control's value doesn't contain specified character
   *
   * @usageNotes
   *
   * ### Validate that the field doesn't have specified character
   *
   * ```typescript
   * const control = new FormControl('test/1', Validators.noChar("/"));
   *
   * control.validate()
   *
   * console.log(control.errors); // ['Error: character "/" is not accepted']
   * ```
   *
   * @returns An error message `Error: character ${character} is not accepted`
   * if the validation check fails, otherwise `null`.
   */
  static noChar(character: string): ValidatorFn {
    return (control: FormControl): ValidationError | null => {
      if (isEmptyInputValue(control.value)) {
        return null; // don't validate empty values to allow optional controls
      }

      return (control.value as string).indexOf(character) !== -1
        ? `Error: character "${character}" is not accepted`
        : null;
    };
  }

  /**
   * @description
   * Validator that requires the control's value doesn't repeat in a given list
   *
   * @usageNotes
   *
   * ### Validate that the field value is unique
   *
   * ```typescript
   * const list = [1, 2, 3]
   *
   * const control = new FormControl('2', Validators.uniqueInList(list));
   *
   * control.validate()
   *
   * console.log(control.errors); // ['Error: an entity with the same name already exists']
   * ```
   *
   * @returns A validator function that returns an message `Error: an entity with the same
   * name already exists` if the validation check fails, otherwise `null`.
   */
  // TODO: document params for all validator that are partialled
  static uniqueInList(list: Array<any>, key?: string): ValidatorFn {
    return (control: FormControl): ValidationError | null => {
      if (isEmptyInputValue(control.value) || !hasValidLength(control.value)) {
        // don't validate empty values to allow optional controls
        // don't validate values without `length` property
        return null;
      }

      return list.findIndex((item) => {
        if (key) {
          if (item[key] === control.value) {
            return true;
          }
        } else {
          if (item === control.value) {
            return true;
          }
        }
      }) >= 0
        ? `Error: an entity with the same name already exists`
        : null;
    };
  }

  /**
   * @description
   * Validator that requires the control's value exists a given list
   *
   * @usageNotes
   *
   * ### Validate that the field value exists in list
   *
   * ```typescript
   * const list = [1, 2, 3]
   *
   * const control = new FormControl('4', Validators.inList(list));
   *
   * control.validate()
   *
   * console.log(control.errors); // ['Error: an entity is invalid']
   * ```
   *
   * @returns A validator function that returns an message `Error: an entity is invalid` if the validation check fails, otherwise `null`.
   */
  static inList(list: Array<any>, key?: string): ValidatorFn {
    return (control: FormControl): ValidationError | null => {
      if (isEmptyInputValue(control.value) || !hasValidLength(control.value)) {
        // don't validate empty values to allow optional controls
        // don't validate values without `length` property
        return null;
      }

      return list.findIndex(
        (item) => (key ? item[key] : item) === control.value
      ) === -1
        ? `Error: an entity is invalid`
        : null;
    };
  }

  /**
   * @description
   * Validator that checks the control correct date value.
   *
   * @usageNotes
   *
   *  ### Validate that the field date value is after or equal passed date
   *
   * ```typescript
   * const date = new Date()
   *
   * const control = new FormControl(new Date(), Validators.afterOrEqual(date));
   *
   * control.validate()
   *
   * console.log(control.errors); // ['Incorrect date']
   * ```
   *
   * @returns An error message `Incorrect date`
   * if the validation check fails, otherwise `null`.
   */
  static afterOrEqual(dateToCompareWith: Date): ValidatorFn {
    return (control: FormControl): ValidationError | null => {
      const date = control.value as Date;
      if (date && date instanceof Date) {
        date.setSeconds(0);
        dateToCompareWith.setSeconds(0);

        if (date.toString() === dateToCompareWith.toString()) {
          return null;
        }
      }

      return date && date >= dateToCompareWith ? null : "Incorrect date";
    };
  }

  /**
   * @description
   * Validator that requires the control's value pass a JSON validation test.
   *
   * @usageNotes
   *
   * ### Validate that the field value is valid JSON.
   *
   * ```typescript
   * const control = new FormControl('{"test"}', Validators.json);
   *
   * control.validate()
   *
   * console.log(control.errors); // ['Not a valid JSON']
   * ```
   *
   * @returns An error message `Not a valid JSON`
   * if the validation check fails, otherwise `null`.
   */
  static json(control: FormControl): ValidationError | null {
    if (isEmptyInputValue(control.value)) {
      return null;
    }

    return isValidJSON(control.value as string) ? null : "Not a valid JSON";
  }

  /**
   * @description
   * Validator that requires the control's value pass array validation test.
   *
   * @usageNotes
   *
   * ### Validate that the field value is valid array.
   *
   * ```typescript
   * const control = new FormControl("str", Validators.array);
   *
   * control.validate()
   *
   * console.log(control.errors); // ['Not a valid array']
   * ```
   *
   * @returns An error message `Not a valid array`
   * if the validation check fails, otherwise `null`.
   */
  static array(control: FormControl): ValidationError | null {
    if (isEmptyInputValue(control.value)) {
      return null;
    }

    return isValidArray(control.value) ? null : "Not a valid array";
  }

  /**
   * @description
   * Validator that requires the control's value is empty.
   *
   * @usageNotes
   *
   * ### Validate that the field value is empty.
   *
   * ```typescript
   * const control = new FormControl('str', Validators.empty);
   *
   * control.validate()
   *
   * console.log(control.errors); // ['Value should be empty']
   * ```
   *
   * @returns An error message `Value should be empty`
   * if the validation check fails, otherwise `null`.
   */
  static empty(control: FormControl): ValidationError | null {
    return isEmptyInputValue(control.value) ? null : "Value should be empty";
  }

  // static isOfDataTypeList(control: FormControl): ValidationError | null {
  //   if (isEmptyInputValue(control.value)) {
  //     return null;
  //   }

  //   if (control.value) {
  //     Z
  //   }
  //   return "Right operator is not of LIST type";
  // }
}
