import { MutationTree } from "vuex";
import { FormState } from "./Form.store";
import {
  FormBuilderMode,
  FormBuilderUserRole,
} from "@procesio/procesio-ui-builder";
import { FormTemplateStatus } from "@/services/form/FormTemplate.model";

export enum MutationTypes {
  SET_ID = "SET_ID",
  SET_NAME = "SET_NAME",
  SET_MODE = "SET_MODE",
  SET_STATUS = "SET_STATUS",
  SET_USERS = "SET_USERS",
  SET_ACTIVE_STATE = "SET_ACTIVE_STATE",
  SET_SAVED_STATE = "SET_SAVED_STATE",
  SET_HIDE_BRANDING_STATE = "SET_HIDE_BRANDING_STATE",
}

export type Mutations<S = FormState> = {
  [MutationTypes.SET_ID](state: S, payload: string | null): void;

  [MutationTypes.SET_NAME](state: S, payload: string): void;

  [MutationTypes.SET_MODE](state: S, payload: FormBuilderMode): void;

  [MutationTypes.SET_STATUS](state: S, payload: FormTemplateStatus): void;

  [MutationTypes.SET_USERS](
    state: S,
    payload: {
      id: string;
      name: string;
      roles: [FormBuilderUserRole];
    }[]
  ): void;

  [MutationTypes.SET_ACTIVE_STATE](state: S, payload: boolean): void;

  [MutationTypes.SET_SAVED_STATE](state: S, payload: boolean): void;

  [MutationTypes.SET_HIDE_BRANDING_STATE](state: S, payload: boolean): void;
};

export const mutations: MutationTree<FormState> & Mutations = {
  [MutationTypes.SET_ID](state, payload) {
    state.id = payload;
  },

  [MutationTypes.SET_NAME](state, payload) {
    state.name = payload;
  },

  [MutationTypes.SET_MODE](state, payload) {
    state.mode = payload;
  },

  [MutationTypes.SET_STATUS](state, payload) {
    state.status = payload;
  },

  [MutationTypes.SET_USERS](state, payload) {
    state.users = payload;
  },

  [MutationTypes.SET_ACTIVE_STATE](state, payload) {
    state.isActive = payload;
  },

  [MutationTypes.SET_SAVED_STATE](state, payload) {
    state.isSaved = payload;
  },

  [MutationTypes.SET_HIDE_BRANDING_STATE](state, payload) {
    state.hideBrading = payload;
  },
};
