import { PROCESIO_WORKSPACE_ID } from "@/services/user/workspace/UserWorkspace.service";
import { store } from "@/store";

export enum UserRole {
  Member = "Member",
  Admin = "Admin",
  Owner = "Owner",
  CoOwner = "Co-Owner",
  CanAddActions = "CanAddActions",
}

export const UserRoleLabelsMap = {
  [UserRole.Member]: "Member",
  [UserRole.Admin]: "Admin",
  [UserRole.Owner]: "Owner",
  [UserRole.CoOwner]: "Co-Owner",
};

export enum UserPermission {
  OWNER = "OWNER",
  ADMIN = "ADMIN",
  MEMBER = "MEMBER",
  MEMBER_BUSINESS_WS = "MEMBER_BUSINESS_WS",
  MEMBER_PERSONAL_WS = "MEMBER_PERSONAL_WS",
  CAN_ADD_ACTIONS = "CAN_ADD_ACTIONS",
}

export const hasUserPermission = (permission: UserPermission) => {
  switch (permission) {
    case UserPermission.OWNER:
      return store.getters.isOwner();
    case UserPermission.ADMIN:
      return store.getters.isAdmin();
    case UserPermission.MEMBER:
      return (
        !!store.getters.activeWorkspace &&
        (store.getters.hasRole(UserRole.Member) ||
          store.getters.activeWorkspace.id === PROCESIO_WORKSPACE_ID)
      );
    case UserPermission.MEMBER_BUSINESS_WS:
      return (
        !!store.getters.activeWorkspace && !!store.getters.activeWorkspace.id
      );
    case UserPermission.MEMBER_PERSONAL_WS:
      return (
        !!store.getters.activeWorkspace &&
        !store.getters.activeWorkspace.id &&
        store.getters.hasRole(UserRole.Member)
      );
    case UserPermission.CAN_ADD_ACTIONS:
      return store.getters.hasRole(UserRole.CanAddActions);
  }
};

export enum UserInitationState {
  Pending = 1,
  Approved = 2,
  Expired = 3,
}

export interface UserListItem {
  userId: string; // user guid
  email: string;
  firstName: string;
  lastName: string;
  role: UserRole;
  invitationStatus: UserInitationState;
  expiryDate?: string | null;
  joinDate?: string | null;
}

export interface ConstantsRequest {
  description: string;
  button_text: string;
  button_link: string;
}

export interface Constants {
  request: ConstantsRequest;
  discover: Array<{
    title: string;
    icon_path: string;
    description: string;
    link: string;
    key: string;
  }>;
  resources: {
    current_plan: string;
    current_plan_description: string;
    refresh_button_text: string;
  };
  invite: {
    title: string;
    description: string;
    button_text: string;
    input_placeholder: string;
  };
  information: Array<{
    title: string;
    icon: string;
    link: string;
    key: string;
  }>;
  all_documentation: string;
  linktr: string;
  prod_constants_url: string;
  share: {
    discord: string;
    capterra: string;
    G2: string;
  };
}
