let ls: Storage;

if ('localStorage' in window && window.localStorage) {
  ls = window.localStorage;
} else {
  throw new Error('TODO: Implement stub for localStorage');
}

const _get = (key: string) => {
  const item = ls.getItem(key);

  const numPatt = new RegExp(/^\d+$/); //eslint-disable-line
  const jsonPatt = new RegExp(/[\[\{].*[\}\]]/); //eslint-disable-line

  if (item) {
    if (jsonPatt.test(item)) {
      return JSON.parse(item);
    } else if (numPatt.test(item)) {
      return parseFloat(item);
    } else {
      return item;
    }
  } else {
    return null;
  }
};

const _set = (key: string, value: unknown) => {
  try {
    if (typeof value === 'object') {
      ls.setItem(key, JSON.stringify(value));
    } else if (typeof value === 'string') {
      ls.setItem(key, value);
    } else if (typeof value === 'number') {
      ls.setItem(key, value.toString());
    }
  } catch (error) {
    console.error(error);
  }
};

const _remove = (key: string) => ls.removeItem(key);

const _clear = () => ls.clear();

function accessor(key: string, value?: unknown) {
  if (typeof value === 'undefined' || value === null) {
    return _get(key);
  }

  return _set(key, value);
}

//eslint-disable-next-line
namespace accessor {
  export const get = _get;

  export const set = _set;

  export const remove = _remove;

  export const clear = _clear;
}

export default accessor;
