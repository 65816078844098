import ls from "@/services/localStorage";

export default class UserLocalStorage {
  static readonly TOKEN: string = "token";
  static readonly REFRESH: string = "refresh_token";
  static readonly EXPIRESIN: string = "refresh_expires_in";

  static getToken() {
    return ls(this.TOKEN);
  }

  static setToken(token: string) {
    ls(this.TOKEN, token);
  }

  static removeToken() {
    ls.remove(this.TOKEN);
  }

  /** refresh token */
  static getRefreshToken() {
    return ls(this.REFRESH);
  }

  static setRefreshToken(token: string) {
    ls(this.REFRESH, token);
  }

  static removeRefreshToken() {
    ls.remove(this.REFRESH);
  }

  /** expire period */
  static getTokenExpiresIn() {
    return ls(this.EXPIRESIN);
  }

  static setTokenExpiresIn(refresh: number) {
    ls(this.EXPIRESIN, refresh);
  }

  static removeTokenExpiresIn() {
    ls.remove(this.EXPIRESIN);
  }
}
