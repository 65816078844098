import { MutationTree } from "vuex";
import { UserWorkspace } from "@/services/user/workspace/UserWorkspace.service";
import { UserState } from "./User.store";
import { Event } from "@/services/user/event/Event.model";

export enum MutationTypes {
  SET_USER_ID = "SET_USER_ID",
  SET_USER_EMAIL = "SET_USER_EMAIL",
  SET_FIRST_NAME = "SET_FIRST_NAME",
  SET_LAST_NAME = "SET_LAST_NAME",
  SET_USER_CREATED_AT = "SET_USER_CREATED_AT",
  SET_TOKEN = "SET_TOKEN",
  SET_REFRESH_TOKEN = "SET_REFRESH_TOKEN",
  SET_TOKEN_EXPIRES_IN = "SET_TOKEN_EXPIRES_IN",
  SET_WORKSPACES = "SET_WORKSPACES",
  SET_ACTIVE_WORKSPACE = "SET_ACTIVE_WORKSPACE",
  SET_EVENTS = "SET_EVENTS",
  READ_EVENT = "READ_EVENT",
}

export type Mutations<S = UserState> = {
  [MutationTypes.SET_USER_ID](state: S, payload: string | null): void;
  [MutationTypes.SET_USER_EMAIL](state: S, payload: string): void;
  [MutationTypes.SET_FIRST_NAME](state: S, payload: string): void;
  [MutationTypes.SET_LAST_NAME](state: S, payload: string): void;
  [MutationTypes.SET_USER_CREATED_AT](state: S, payload: string | null): void;
  [MutationTypes.SET_TOKEN](state: S, payload: string | null): void;
  [MutationTypes.SET_REFRESH_TOKEN](state: S, payload: string | null): void;
  [MutationTypes.SET_TOKEN_EXPIRES_IN](state: S, payload: number | null): void;
  [MutationTypes.SET_WORKSPACES](state: S, payload: UserWorkspace[]): void;
  [MutationTypes.SET_ACTIVE_WORKSPACE](state: S, payload: UserWorkspace): void;
  [MutationTypes.SET_EVENTS](state: S, payload: Event[]): void;
  [MutationTypes.READ_EVENT](state: S, payload: string | null): void;
};

export const mutations: MutationTree<UserState> & Mutations = {
  [MutationTypes.SET_USER_ID](state, payload) {
    state.userId = payload;
  },
  [MutationTypes.SET_USER_EMAIL](state, payload) {
    state.userEmail = payload;
  },
  [MutationTypes.SET_FIRST_NAME](state, payload) {
    state.firstName = payload;
  },
  [MutationTypes.SET_LAST_NAME](state, payload) {
    state.lastName = payload;
  },
  [MutationTypes.SET_USER_CREATED_AT](state, payload) {
    state.createdAt = payload;
  },
  [MutationTypes.SET_TOKEN](state, payload) {
    state.token = payload;
  },
  [MutationTypes.SET_REFRESH_TOKEN](state, payload) {
    state.refreshToken = payload;
  },
  [MutationTypes.SET_TOKEN_EXPIRES_IN](state, payload) {
    state.tokenExpiresIn = payload;
  },
  [MutationTypes.SET_WORKSPACES](state, payload) {
    state.workspaces = payload;
  },
  [MutationTypes.SET_ACTIVE_WORKSPACE](state, payload) {
    state.activeWorkspace = payload;
  },
  [MutationTypes.SET_EVENTS](state, payload) {
    state.events = payload
      .map((event) => ({
        ...event,
        triggers: event.triggers.sort(
          (triggerA, triggerB) => triggerA.order - triggerB.order
        ),
      }))
      .sort((eventA, eventB) => eventB.date.localeCompare(eventA.date));
  },
  [MutationTypes.READ_EVENT](state, id) {
    state.events.forEach((event) => {
      let isAcknowledged = event.ack;
      if (!id || event.eventId === id) {
        isAcknowledged = true;
      }

      event.ack = isAcknowledged;
    });
  },
};
