import Vue from "vue";
import App from "./App.vue";

import ControlsManager from "@/modules/ProcessDesigner/components/Controls/ControlsManager/ControlsManager.component.vue";
import TInput from "@/modules/ProcessDesigner/components/Controls/TableInput/TInput.component.vue";
import Editor from "@/modules/ProcessDesigner/components/CodeEditors/Editor/Editor.component.vue";

import router from "./router";

import VueCryptojs from "vue-cryptojs";
import VueFroala from "vue-froala-wysiwyg";

import { Userpilot } from "userpilot";
Userpilot.initialize("NX-f6819278");
// Userpilot.anonymous();
import { store } from "./store";

import "./filters";

import "@/plugins/keyboard";

Vue.use(VueCryptojs);
Vue.use(VueFroala);

Vue.config.productionTip = false;

// register ControlsManager globally to try to solve PRC-2682
Vue.component("pup-control-global", ControlsManager);
Vue.component("pup-table-input", TInput);
Vue.component("pup-editor", Editor);

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
