import { UserRole } from "@/services/administration/userManagement/UserManagement.model";
import {
  DefaultWorkspaceKey,
  UserWorkspace,
  UserWorkspaceType,
} from "@/services/user/workspace/UserWorkspace.service";
import { UserState } from "./User.store";
import ls from "@/services/localStorage";
import { LsWorkspaceKey } from "@/store/user/User.actions";

export default {
  userId: (state: UserState) => state.userId,
  userEmail: (state: UserState) => state.userEmail,
  firstName: (state: UserState) => state.firstName,
  lastName: (state: UserState) => state.lastName,
  fullName: (state: UserState) =>
    state.firstName && state.lastName
      ? state.firstName + " " + state.lastName
      : "",
  createdAt: (state: UserState) => state.createdAt,
  // auth
  token: (state: UserState) => state.token,
  isAuthenticated: (state: UserState) => !!state.token,
  refreshToken: (state: UserState) => state.refreshToken,
  tokenExpiresIn: (state: UserState) => state.tokenExpiresIn,
  // permissions
  hasRole:
    (state: UserState) =>
    (role: UserRole, id: string | null = null) => {
      const workspace = state.workspaces.find(
        (group: UserWorkspace) => group.id === (id || state.activeWorkspace?.id)
      );

      if (!workspace) {
        return false;
      }

      return workspace.roles?.includes(role);
    },
  isMember:
    (_: UserState, getters: any) =>
    (id: string | null = null) =>
      getters.hasRole(UserRole.Member, id),
  isAdmin:
    (_: UserState, getters: any) =>
    (id: string | null = null) =>
      getters.hasRole(UserRole.Admin, id),
  isOwner:
    (_: UserState, getters: any) =>
    (id: string | null = null) =>
      getters.hasRole(UserRole.Owner, id) ||
      getters.hasRole(UserRole.CoOwner, id),
  canAddActions:
    (_: UserState, getters: any) =>
    (id: string | null = null) =>
      getters.hasRole(UserRole.CanAddActions, id),
  isWorkspaceAllowed:
    (state: UserState, getters: any) =>
    (id: string | null = null) => {
      const workspace = state.workspaces.find(
        (group: UserWorkspace) => group.id === (id || state.activeWorkspace?.id)
      );

      if (!workspace) {
        return false;
      }

      if (workspace.type === UserWorkspaceType.MASTER) {
        return getters.isOwner(workspace.id);
      }

      return true;
    },
  // workspaces
  activeWorkspace: (state: UserState) => state.activeWorkspace,
  activeWorkspaceKey: (state: UserState) =>
    state.activeWorkspace
      ? state.activeWorkspace.workspace
      : DefaultWorkspaceKey,
  activeWorkspaceKeyFullName: (state: UserState, getters: any) => {
    if (!state.activeWorkspace) {
      return null;
    }

    let name = state.activeWorkspace.workspace;

    const parentWorkspace = getters.getParentWorkspace(state.activeWorkspace);

    if (parentWorkspace) {
      name = parentWorkspace.workspace + "/" + name;
    }
    return name;
  },
  personalWorkspace: (state: UserState) =>
    state.workspaces.find((ws: UserWorkspace) => !ws.id) || null,
  getParentWorkspace: (state: UserState) => (childWorkspace: UserWorkspace) =>
    childWorkspace.parentId
      ? state.workspaces.find(
          (ws: UserWorkspace) => ws.id === childWorkspace.parentId
        ) || null
      : null,

  activeWorkspaceId: (state: UserState) => {
    // before workspaces loaded
    if (state.workspaces.length > 0) {
      return state.activeWorkspace ? state.activeWorkspace.id : null;
    }
    return ls.get(LsWorkspaceKey);
  },
  activeWorkspaceIdStringified: (_: UserState, getters: any) =>
    getters.activeWorkspaceId || "",
  workspaces: (state: UserState) => state.workspaces,
  isInPersonalWorkspace: (_: UserState, getters: any) =>
    !getters.activeWorkspaceId,
  // events
  events: (state: UserState) => state.events,
};
