import { parseJwt, TokenBody } from "@/utils/parseJwt";

export default class UserService {
  static getUserData(token: string) {
    const data: {
      id: string | null;
      email: string;
      created: Date | null;
      firstName: string;
      lastName: string;
    } = {
      id: null,
      email: "",
      created: null,
      firstName: "",
      lastName: "",
    };

    const tokenBody: TokenBody | null = parseJwt(token);

    if (!tokenBody) {
      return data;
    }

    data.id = tokenBody.sub;
    data.email = tokenBody.email;
    data.created = new Date(tokenBody.created_timestamp);
    data.firstName = tokenBody.given_name;
    data.lastName = tokenBody.family_name;

    return data;
  }
}
