import AuthService from "@/services/auth/Auth.service";
import { store } from "@/store";
import { ActionTypes as UserActionTypes } from "@/store/user/User.actions";
import RestUrls from "@/services/Rest.paths";
import { isGuid } from "@/utils/type/guid";
import { config } from "@/config";
import { ErrorCode } from "@/utils/serverError";

export interface ErrorContent {
  statusCode: number;
  target: string;
  value: string;
}

export interface RestResponse<T> {
  is_error?: boolean;
  error_content?: ErrorContent[];
  content?: T;
  status?: number;
}

export interface PaginatedResponse<T> {
  pageItemCount: number;
  pageItems: T[];
  pageNumber: number;
  totalItemCount: number;
}

interface ProcesioRequestParams {
  url: string;
  isAuth?: boolean;
  baseUrl?: string;
  workspaced?: boolean;
  header?: Record<string, any>;
  queryParams?: Record<string, any>;
}

interface RequestParams extends ProcesioRequestParams {
  method?: RestMethods;
  data?: Record<string, any> | string | null;
  customWorkspace?: string;
}

interface PostParams extends ProcesioRequestParams {
  data: Record<string, any> | string;
  customWorkspace?: string;
}

interface GetParams extends ProcesioRequestParams {
  customWorkspace?: string;
}

interface DeleteParams extends ProcesioRequestParams {
  data?: Record<string, any> | string;
}

enum RestMethods {
  GET = "GET",
  POST = "POST",
  DELETE = "DELETE",
  PUT = "PUT",
  PATCH = "PATCH",
}

export default class RestService {
  static endpoint = config.protocol;

  static domain = config.api;

  static get<T>({
    url,
    isAuth,
    baseUrl,
    workspaced = true,
    customWorkspace,
    header,
    queryParams,
  }: GetParams): Promise<RestResponse<T>> {
    return RestService.request<T>({
      url,
      isAuth,
      method: RestMethods.GET,
      baseUrl,
      workspaced,
      header,
      queryParams,
      customWorkspace,
    });
  }

  static post<T>({
    url,
    data,
    isAuth,
    baseUrl,
    workspaced = true,
    customWorkspace,
    header,
    queryParams,
  }: PostParams): Promise<RestResponse<T>> {
    return this.request({
      url,
      data,
      isAuth,
      method: RestMethods.POST,
      baseUrl,
      workspaced,
      customWorkspace,
      header,
      queryParams,
    });
  }

  static patch<T>({
    url,
    data,
    isAuth,
    baseUrl,
    workspaced = true,
    customWorkspace,
    header,
    queryParams,
  }: PostParams): Promise<RestResponse<T>> {
    return this.request({
      url,
      data,
      isAuth,
      method: RestMethods.PATCH,
      baseUrl,
      workspaced,
      customWorkspace,
      header,
      queryParams,
    });
  }

  static delete<T>({
    url,
    data,
    isAuth,
    baseUrl,
    workspaced = true,
    header,
    queryParams,
  }: DeleteParams): Promise<RestResponse<T>> {
    return RestService.request({
      url,
      data,
      isAuth,
      method: RestMethods.DELETE,
      baseUrl,
      workspaced,
      header,
      queryParams,
    });
  }

  static put<T>({
    url,
    data,
    isAuth,
    baseUrl,
    workspaced = true,
    header,
    queryParams,
  }: PostParams): Promise<RestResponse<T>> {
    return RestService.request<T>({
      url,
      data,
      isAuth,
      method: RestMethods.PUT,
      baseUrl,
      workspaced,
      header,
      queryParams,
    });
  }

  private static async request<T>({
    method,
    url,
    isAuth = false,
    data = null,
    baseUrl,
    workspaced = true,
    customWorkspace,
    header,
    queryParams,
  }: RequestParams): Promise<RestResponse<T>> {
    let isBadRequest = false;
    let status: number;
    let body = data;

    const headers = new Headers();

    headers.set("Accept", "application/json");

    if (data) {
      if (typeof data === "object" && data.import != undefined) {
        const formData = new FormData();
        formData.append("import", data.import);
        body = formData;
      } else if (data instanceof FormData) {
        body = data;
      } else {
        if (typeof data === "object") {
          headers.set("Content-type", "application/json");

          body = JSON.stringify(data);
        } else if (typeof data === "string") {
          headers.set("Content-type", "application/json");
        } else {
          headers.set("Content-type", "application/x-www-form-urlencoded");
        }
      }
    }

    if (isAuth) {
      if (
        !!store.getters.tokenExpiresIn &&
        new Date().getTime() > store.getters.tokenExpiresIn
      ) {
        await AuthService.refreshToken();
      }

      headers.set("Authorization", `Bearer ${store.getters.token}`);
      headers.set("token", store.getters.token);
      /** TODO : change this */
    }

    if (workspaced) {
      if (customWorkspace) {
        if (isGuid(customWorkspace)) {
          headers.set("workspaceId", customWorkspace);
        } else {
          headers.set("workspace", customWorkspace);
        }
      } else {
        headers.set("workspaceId", store.getters.activeWorkspaceIdStringified);
      }
    }

    if (header) {
      Object.entries(header).forEach(([key, value]) => {
        headers.set(key, value);
      });
    }

    headers.set("realm", "procesio01");

    // headers.set("x-version", "1.15");

    const options = {
      method,
      headers,
      body: body as string,
    };

    if (data && data.import) {
      options.headers.delete("Content-Type");
      delete (options.headers as any)["Content-Type"];
    }

    const base = baseUrl
      ? `${this.endpoint}://${baseUrl}`
      : `${this.endpoint}://${this.domain}`;

    const query = new URLSearchParams(queryParams);

    return fetch(
      `${base}/${url}${query.toString().length > 0 ? "?" + query : ""}`,
      options
    )
      .then(async (response) => {
        const responseContentType = response.headers.get("content-type");

        let jsonResponse: string | null = null;

        if (
          responseContentType &&
          responseContentType.indexOf("application/json") !== -1
        ) {
          try {
            jsonResponse = await response.clone().json();
          } catch (e) {
            console.log("Could not parse response JSON: " + e);
          }
        }

        if (
          response.status === 401 ||
          (response.status === 400 &&
            Array.isArray(jsonResponse) &&
            jsonResponse.findIndex(
              (item) => item.code === ErrorCode.INACTIVE_TOKEN
            ) !== -1)
        ) {
          await AuthService.refreshToken();

          headers.set("Authorization", `Bearer ${store.getters.token}`);

          return fetch(`${base}/${url}`, options).then((response) => {
            if (response.status === 401) {
              store.dispatch(UserActionTypes.SET_TOKEN, null);
              window.location = "/" as unknown as Location;
            }

            return jsonResponse || response.blob();
          });
        }

        isBadRequest = response.status !== 200;
        status = response.status;

        if (
          responseContentType &&
          responseContentType.indexOf("application/json") !== -1
        ) {
          // handle invalid json files downloads
          if (url === RestUrls.API.FILE.DOWNLOAD) {
            const resText = await response.text();
            return new Blob([resText], {
              type: "text/plain",
            });
          } else {
            return jsonResponse;
          }
        } else {
          return response.blob();
        }
      })
      .then((response) =>
        this.createResponse<T>(response, isBadRequest, status)
      );
  }

  static createResponse<T>(
    responseContent: any,
    isBadRequest: boolean,
    status: number
  ) {
    const response: RestResponse<T> = {
      is_error: isBadRequest, //eslint-disable-line
      error_content: isBadRequest ? responseContent : null, //eslint-disable-line
      content: isBadRequest ? null : responseContent,
      status,
    };

    return response;
  }
}
