import { Definition as DelayDefinition } from "@/modules/ProcessDesigner/components/Controls/Delay/Delay.model";
import { MapProcessDataValue } from "@/modules/ProcessDesigner/components/Controls/MapProcessData/MapProcessData.model";
import { DocumentMapperValue } from "@/modules/ProcessDesigner/components/Controls/DocumentMapper/DocumentMapper.model";
import { ActionResponseLazy } from "@/services/actionlist/ActionList.service";
import { store } from "@/store";
import { PdsTypes } from "@procesio/procesio-design-system";
import {
  TabsPayloadOldValue,
  TabsPayloadValue,
} from "@/services/apiCall/tabsPayload/TabsPayload.model";
import { Condition } from "@/modules/CredentialManager/components/Form/Controls.model";
import { EditorLanguage } from "@/modules/ProcessDesigner/components/CodeEditors/Editor/Editor.model";

export interface SettingTab {
  label: string;
  id: string;
  settings: Setting[];
  orderId: number;
}

export type Setting =
  | GenericSetting
  | SelectSetting
  | RadioSetting
  | ButtonSetting
  | SidePanelSetting
  | ToastSetting
  | TabsSetting // TODO: remove
  | TabSetting // TODO: remove
  | TableSetting
  | VerbSelectSetting
  | CredentialSetting
  | GroupCollapseSetting
  | APICallSetting
  | CodeEditoreSetting
  | RequestPayloadSetting
  | ScopedVariableSetting
  | IgnoreSetting
  | ProcessInputOuputSetting
  | ColumnDefinitionSetting
  | DelayDefinitionSetting
  | MapProcessDataSetting
  | DateTimeSetting
  | DocumentMapperSetting
  | NumberSetting;

export enum Direction {
  NONE = 0,
  Input = 1,
  InputOutput = 2,
  Output = 3,
}

export interface Condition {
  dependencyId: string;
  operator: Operator;
  value: unknown;
}

export enum Operator {
  EQUALS = "equals",
}

export interface GenericSetting {
  label: string;
  id: string;
  internalId?: string;
  value:
    | string
    | number
    | boolean
    | Record<string, unknown>
    | Array<unknown>
    | any;
  type: SettingType;
  isRequired?: boolean;
  direction?: Direction;
  rowId?: number;
  columnId?: number;
  dataTypeId: string;
  ////
  testAction?: any;
  isList: boolean;
  status?: PdsTypes.InputStatus | null;
  tooltip?: string;
  limits?: { min: number; max: number };
  condition?: Condition;
  language?: EditorLanguage;
}

export interface SelectSetting extends GenericSetting {
  options: unknown[];
  type: SettingType.SELECT;
}

export interface ScopedVariableSetting extends GenericSetting {
  type: SettingType.SCOPED_VARIABLE;
  value: string;
}
export interface RadioSetting extends GenericSetting {
  options: unknown[];
  type: SettingType.RADIO;
}

export interface ButtonSetting extends GenericSetting {
  type: SettingType.BUTTON;
}

export interface UploadSetting extends GenericSetting {
  type: SettingType.UPLOAD_MODAL;
}

export interface SidePanelSetting extends GenericSetting {
  type: SettingType.SIDEPANEL;
  value: Setting[];
}

export interface ToastSetting extends GenericSetting {
  type: SettingType.TOAST;
}

// TODO: delete after migrated to tabs payload v2
export interface TabsSetting extends GenericSetting {
  type: SettingType.TABS;
  value: TabSetting[];
}

// TODO: delete after migrated to tabs payload v2
export interface TabSetting extends GenericSetting {
  type: SettingType.TAB;
  value: Setting[];
}

// TODO: delete after migrated to tabs payload v2
export interface RequestPayloadOldSetting extends GenericSetting {
  type: SettingType.TABS_PAYLOAD_OLD;
  value: TabsPayloadOldValue;
}

export interface RequestPayloadSetting extends GenericSetting {
  type: SettingType.TABS_PAYLOAD;
  value: TabsPayloadValue;
}

export interface TableSetting extends GenericSetting {
  type: SettingType.TABLE;
  columns: Array<{
    key: string;
    label: string;
    placeholder: string;
    component?: string;
    id: string;
  }>;
  value: unknown[];
}
export interface VerbSelectSetting extends GenericSetting {
  options: unknown[];
  type: SettingType.VERB_SELECT;
}

export interface CredentialSetting extends GenericSetting {
  options: unknown[];
  type: SettingType.CREDENTIAL;
  credentialsTemplateId: string;
}

export interface GroupCollapseSetting extends GenericSetting {
  type: SettingType.GROUP_COLLAPSABLE;
  value: Setting[];
}

export interface APICallSetting extends GenericSetting {
  type: SettingType.API_CAPP;
  value: Setting[];
}

export interface CodeEditoreSetting extends GenericSetting {
  type: SettingType.CODE_EDITOR;
  value: string;
  lang: string;
}

export interface IgnoreSetting extends GenericSetting {
  type: SettingType.IGNORE;
}

export interface ProcessInputOuputSetting extends GenericSetting {
  type: SettingType.PROCESS_INPUT | SettingType.PROCESS_OUTPUT;
  value: Array<{
    id: number;
    subprocess: string;
    process: string;
  }>;
}

export interface ColumnDefinitionSetting extends GenericSetting {
  type: SettingType.COLUMN_DEFINITION;
  value: {
    variable: string;
    rows:
      | Array<{
          columnName: string;
          attribute: null | string;
          id: string;
        }>
      | undefined;
  };
}

export interface DelayDefinitionSetting extends GenericSetting {
  type: SettingType.DELAY_DEFINITION;
  value: DelayDefinition;
}

export interface MapProcessDataSetting extends GenericSetting {
  type: SettingType.MAP_PROCESS_DATA;
  value: Array<MapProcessDataValue>;
}

export interface DocumentMapperSetting extends GenericSetting {
  type: SettingType.DOCUMENT_MAPPER_BUILDER;
  value: Array<DocumentMapperValue>;
}

export interface DateTimeSetting extends GenericSetting {
  type: SettingType.DATE_TIME;
  value: Date | string;
}

export interface NumberSetting extends GenericSetting {
  type: SettingType.NUMBER;
  value: number;
}

/**
 * Note: when you add new setting type and its value has complex structure,
 * do not forget to add:
 * - validator (/ProcessDesigner/Validation/ControlValidators)
 * - parameter getter (/ProcessDesigner/ParameterGetter)
 * - value resetter (/ProcessDesigner/Values/ValueResetter) (optional)
 **/
export enum SettingType {
  TEXT = "text",
  TEXT_BASIC = "test-basic",
  SELECT = "select",
  RADIO = "radio",
  BUTTON = "button",
  MODAL = "modal", // "modal"
  UPLOAD_MODAL = "upload-modal", // "modal:upload"
  EMAIL_MODAL = "email-modal", // "modal:email"
  EMAIL_CONFIG = "email-config",
  FILTER_DATA = "filter-data",
  CHECKBOX = "check-box",
  SIDEPANEL = "side-pannel",
  TOAST = "toast",
  TABS = "tabs", // todo: delete after tabs payload v2
  TAB = "tab", // todo: delete after tabs payload v2
  TABS_PAYLOAD_OLD = "tabs-payload", // todo: delete after tabs payload v2
  TABS_PAYLOAD = "tabs-payload-v2",
  TABLE = "table",
  API_CAPP = "api-endpoint",
  VERB_SELECT = "verb",
  TEXT_AREA = "text-area",
  TEXT_AREA_BASIC = "text-area-basic",
  GROUP_COLLAPSABLE = "group:collapsable",
  CREDENTIAL = "credentials",
  CODE_EDITOR = "code-editor",
  SCOPED_VARIABLE = "any",
  DECISIONAL_DEFAULT = "decisional-default",
  DECISIONAL_CASES = "decisional-case",
  PROCESS_INPUT = "process-inputs",
  PROCESS_OUTPUT = "process-outputs",
  FLOW_LIST = "flow-list",
  COLUMN_DEFINITION = "column-definition",
  IGNORE = "ignore",
  DELAY_TYPE_RADIO_SELECT = "delay-radio-type",
  DELAY_DEFINITION = "delay-definition",
  MAP_PROCESS_DATA = "map-process-data",
  DATE_TIME = "date-time",
  DATA_TYPE = "datatype",
  DOCUMENT_SELECT = "document-select",
  DOCUMENT_MAPPER_BUILDER = "document-mapper",
  NUMBER = "number",
  FILE = "file",
  FORM_TEMPLATE = "form-template",
}

export interface Node {
  id: string;
  templateId: string;
  name: string;
  type: string;
  description: string;
  icon: string;
  configuration: SettingTab[];
  position: { x: number; y: number };
  lineArray: Line[];
  inputPorts: number;
  canDuplicate: boolean;
  canDelete: boolean;
  canSaveActionAsTemplate: boolean;
  outputPorts: number;
  wasDropped: boolean;
  isTestable?: boolean;
  parentId?: string;
  parent?: Node;
  shape: {
    dimension: { width: number; height: number };
    position: { x: number; y: number };
  };
  variableErrorId?: string | null;
}

export interface Line {
  // TODO import from canvas
  nodeStart: Node;
  nodeEnd: Node;
  id: string;
  type?: string;
}

export const isNodeTestable = (node: Node) => {
  if (!node) {
    return false;
  }

  if (typeof node.isTestable !== "undefined") {
    return node.isTestable;
  }

  // backward compatibility for previously saved templates without `isTestable` flag
  const action: ActionResponseLazy | undefined =
    store.state.processes.actionList.find(
      (action: ActionResponseLazy) =>
        action.actionId === node.templateId && action.isProcesioAction
    );

  if (!action) {
    return false;
  }

  return action.isTestable;
};
