import { store } from "@/store";
import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import {
  RouteName as ActionDesignerRouteName,
  routes as actionDesignerRoutes,
} from "@/modules/CustomActionDesigner/application/router";
import {
  RouteName as ResourceMapRouteName,
  routes as resourceMapRoutes,
} from "@/modules/ResourceMap/application/router";
import { ActionTypes as UserActionTypes } from "@/store/user/User.actions";

/** AUTH */
const LogIn = () => import("@/modules/Auth/Login/LogIn.component.vue");
const ChangePass = () =>
  import("@/modules/Auth/Login/ChangePass.component.vue");
const ResetPass = () => import("@/modules/Auth/Login/ResetPass.component.vue");
const Activation = () =>
  import("@/modules/Auth/Activation/Activation.component.vue");
const CreateAccount = () =>
  import("@/modules/Auth/CreateAccount/CreateAccount.component.vue");

/** APP */
const Admin = () => import("@/modules/Admin/Admin.component.vue");
const Processes = () =>
  import("@/modules/ProcessDesigner/components/Processes.component.vue");
const Dashboard = () => import("@/modules/Dashboard/Dashboard.component.vue");

/** MODULES */
/** DESIGNER */
const Designer = () =>
  import(
    "@/modules/ProcessDesigner/components/Designer/Designer.component.vue"
  );
const ProcessTopbar = () =>
  import(
    "@/modules/ProcessDesigner/components/Topbar/ProcessTopbar/ProcessTopbar.component.vue"
  );

const ProcessList = () =>
  import(
    "@/modules/ProcessDesigner/components/ProcessList/ProcessList.component.vue"
  );
const InstancesList = () =>
  import("@/modules/ProcessDesigner/instances/InstancesList.component.vue");
const InstanceDetails = () =>
  import(
    "@/modules/ProcessDesigner/instances/instanceDetails/InstanceDetails.component.vue"
  );
const InstanceTopbar = () =>
  import(
    "@/modules/ProcessDesigner/components/Topbar/InstanceTopbar/InstanceTopbar.component.vue"
  );

/** DATA MODEL */
const DataModelCreate = () =>
  import(
    "@/modules/DataModelDesigner/DataModuleCreate/DataModelCreate.component.vue"
  );
const DataModelDesigner = () =>
  import("@/modules/DataModelDesigner/DataModelDesigner.vue");
const DataModelList = () =>
  import(
    "@/modules/DataModelDesigner/DataModelList/DataModelList.component.vue"
  );

/** CREDENTIAL MANAGER */
const CredentialModule = () =>
  import(
    "@/modules/CredentialManager/components/CredentialManager.component.vue"
  );
const CredentialList = () =>
  import(
    "@/modules/CredentialManager/components/CredentialList/CredentialList.component.vue"
  );

/** TEMPLATES DESIGNER */
const TemplateDesigner = () =>
  import("@/modules/TemplateDesigner/TemplateDesigner.vue");
const TemplateDesignerList = () =>
  import(
    "@/modules/TemplateDesigner/TemplatesList/TemplatesList.component.vue"
  );
const TemplateDesignerAction = () =>
  import(
    "@/modules/TemplateDesigner/TemplateDesignerAction/TemplateAction.component.vue"
  );
const TemplateDesignerTopbar = () =>
  import("@/modules/TemplateDesigner/components/TemplateTopbar.component.vue");

/** AUTOMATION */
const Automation = () =>
  import("@/modules/Automation/Automation.component.vue");
const Webhook = () =>
  import("@/modules/Automation/Webhooks/List/List.component.vue");
const Schedule = () =>
  import("@/modules/Automation/Schedules/List/ScheduleList.component.vue");

/** SETTINGS */
const Api = () => import("@/modules/Settings/APIKey/APIKeyTable.component.vue");
const Users = () =>
  import(
    "@/modules/Administration/UserManagement/UserManagementTable.component.vue"
  );

/** ANALYTICS */
const Analytics = () =>
  import("@/modules/Resources/Analytics/Analytics.component.vue");
const ProcessAnalytics = () =>
  import(
    "@/modules/Resources/Analytics/ProcessAnalytics/ProcessAnalytics.component.vue"
  );
const ProcessInstanceAnalytics = () =>
  import(
    "@/modules/Resources/Analytics/ProcessInstanceAnalytics/ProcessInstanceAnalytics.component.vue"
  );

/** ADMINISTRATION */
const Administration = () =>
  import("@/modules/Administration/Administration.component.vue");

/** WORKSPACES */
const WorkspaceManagement = () =>
  import(
    "@/modules/Administration/WorkspaceManagement/Table/WorkspaceManagement.component.vue"
  );

/** SUBSCRIPTION */
const Subscription = () =>
  import("@/modules/Resources/Subscriptions/Subscriptions.component.vue");

/** CUSTOM ACTION DESIGNER */

// const WorkspaceUsers = () =>
//   import("@/modules/Workspaces/UserManagement/UserManagementTable.component.vue");

/** FORM */
const Form = () => import("@/modules/Form/components/Form.component.vue");
const FormList = () =>
  import("@/modules/Form/components/FormList/FormList.component.vue");
const FormTopbar = () =>
  import("@/modules/Form/components/Topbar/Topbar.component.vue");
const FormBuilder = () =>
  import("@/modules/Form/components/Builder/Builder.component.vue");
const FormFill = () =>
  import("@/modules/Form/components/Fill/Fill.component.vue");

const FormInstanceList = () =>
  import(
    "@/modules/Form/components/FormInstanceList/FormInstanceList.component.vue"
  );
const FormInstanceTopbar = () =>
  import(
    "@/modules/Form/components/FormInstanceList/Topbar/Topbar.component.vue"
  );
const FormAssignmentList = () =>
  import(
    "@/modules/Form/components/AssignmentList/AssignmentList.component.vue"
  );

import { DefaultTitleSuffix, getTitleByRoute, setTitle } from "./titleHelper";
import ApplicationService from "@/services/application";
import {
  hasUserPermission,
  UserPermission,
} from "@/services/administration/userManagement/UserManagement.model";
import {
  UserWorkspace,
  UserWorkspaceType,
} from "@/services/user/workspace/UserWorkspace.service";

Vue.use(VueRouter);

/**
 * Name is used to be shown on the top bar.
 *
 * One more feature is routing by name,
 *  ideally, name should be unique, that is why in some names spaces are added
 */
export enum RouteName {
  LOGIN = "Login",
  ADMIN = "Admin",
  DASHBOARD = "Dashboard",
  CREDENTIAL_MANAGER = "Credential Manager",
  DATA_MODEL_DESIGNER = "Data Model Designer",
  DATA_MODEL_DESIGNER_CREATE = "Data Model Designer ",
  DATA_MODEL_DESIGNER_EDIT = "Data Model Designer  ",
  PROCESS_LIST = "Process Designer",
  PROCESS_DESIGNER = "Designer",
  PROCESS_INSTANCES = "Instances",
  PROCESS_LEGACY_INSTANCES = "Legacy Instances",
  PROCESS_DETAILS = "Details",
  SETTINGS = "Settings",
  API = "Api Key",
  USERS = "Users",
  AUTOMATION = "Automation",
  SCHEDULES = "Schedule ",
  WEBHOOKS = "Webhook  ",
  PROCESS_ANALYTICS = "Analytics",
  PROCESS_INSTANCE_ANALYTICS = "Analytics ",
  PROCESS_LEGACY_INSTANCE_ANALYTICS = "Analytics Legacy ",
  DOCUMENT_DESIGNER = "Document Designer",
  DOCUMENT_DESIGNER_ACTION = "Document Designer ",
  DOCUMENT_DESIGNER_ACTION_EDIT = "Document Designer  ",
  WORKSPACES = "Workspaces",
  WORKSPACES_MANAGEMENT = "Workspaces ",
  SUBSCRIPTIONS = "Subscription and billing",
  FORM_LIST = "Form Designer",
  FORM_BUILDER = "Form Designer ",
  FORM_PUBLIC = "Form (public)",
  FORM_PRIVATE = "Form (private)",
  FORM_INSTANCES = "Form Instances",
  FORM_INSTANCE_EDIT = "Form Instance edit",
  FORM_INSTANCE_DETAILS = "Form Instance Details",
  FORM_ASSIGNMENTS = "Forms ",
  FORM_ASSIGNMENT_REVIEW = "Form Assignment Review",
  // ADMINISTRATION_WORKSPACE_USERS = "Administration  ",
}

export const RouteNamePermissions: {
  [key in
    | RouteName
    | ActionDesignerRouteName
    | ResourceMapRouteName]: UserPermission[];
} = {
  [RouteName.LOGIN]: [],
  [RouteName.ADMIN]: [],
  [RouteName.DASHBOARD]: [],
  [RouteName.FORM_PUBLIC]: [],
  [RouteName.FORM_PRIVATE]: [],
  [RouteName.CREDENTIAL_MANAGER]: [UserPermission.MEMBER],
  [RouteName.DATA_MODEL_DESIGNER]: [UserPermission.MEMBER],
  [RouteName.DATA_MODEL_DESIGNER_CREATE]: [UserPermission.MEMBER],
  [RouteName.DATA_MODEL_DESIGNER_EDIT]: [UserPermission.MEMBER],
  [RouteName.PROCESS_LIST]: [UserPermission.MEMBER],
  [RouteName.PROCESS_DESIGNER]: [UserPermission.MEMBER],
  [RouteName.PROCESS_INSTANCES]: [UserPermission.MEMBER],
  [RouteName.PROCESS_LEGACY_INSTANCES]: [UserPermission.MEMBER],
  [RouteName.PROCESS_DETAILS]: [UserPermission.MEMBER],
  [RouteName.SETTINGS]: [UserPermission.MEMBER],
  [RouteName.API]: [UserPermission.MEMBER],
  [RouteName.USERS]: [UserPermission.MEMBER_BUSINESS_WS],
  [RouteName.AUTOMATION]: [UserPermission.MEMBER],
  [RouteName.SCHEDULES]: [UserPermission.MEMBER],
  [RouteName.WEBHOOKS]: [UserPermission.MEMBER],
  [RouteName.PROCESS_ANALYTICS]: [UserPermission.MEMBER],
  [RouteName.PROCESS_INSTANCE_ANALYTICS]: [UserPermission.MEMBER],
  [RouteName.PROCESS_LEGACY_INSTANCE_ANALYTICS]: [UserPermission.MEMBER],
  [RouteName.DOCUMENT_DESIGNER]: [UserPermission.MEMBER],
  [RouteName.DOCUMENT_DESIGNER_ACTION]: [UserPermission.MEMBER],
  [RouteName.DOCUMENT_DESIGNER_ACTION_EDIT]: [UserPermission.MEMBER],
  [ActionDesignerRouteName.CUSTOM_ACTION_DESIGNER]: [UserPermission.MEMBER],
  [ResourceMapRouteName.RESOURCE_MAP]: [UserPermission.MEMBER],
  [RouteName.WORKSPACES]: [UserPermission.OWNER],
  [RouteName.WORKSPACES_MANAGEMENT]: [UserPermission.OWNER],
  [RouteName.WORKSPACES_MANAGEMENT]: [UserPermission.OWNER],
  [RouteName.SUBSCRIPTIONS]: [UserPermission.OWNER],
  [RouteName.FORM_LIST]: [UserPermission.MEMBER],
  [RouteName.FORM_BUILDER]: [UserPermission.MEMBER],
  [RouteName.FORM_INSTANCES]: [UserPermission.MEMBER],
  [RouteName.FORM_INSTANCE_EDIT]: [UserPermission.MEMBER],
  [RouteName.FORM_INSTANCE_DETAILS]: [UserPermission.MEMBER],
  [RouteName.FORM_ASSIGNMENTS]: [UserPermission.MEMBER],
  [RouteName.FORM_ASSIGNMENT_REVIEW]: [UserPermission.MEMBER],
};

const routeGuard = async (
  to: Route,
  from: Route,
  next: NavigationGuardNext
) => {
  const workpaceCheckoutRoutes = [
    RouteName.PROCESS_DESIGNER,
    RouteName.PROCESS_DETAILS,
    RouteName.FORM_PRIVATE,
    RouteName.FORM_PUBLIC,
    RouteName.FORM_ASSIGNMENT_REVIEW,
  ];

  // localhost:8080/admin/form-assignments/f0b71955-77b5-4a1e-8807-9c884bfc60e9#0d98d050-3bab-4b64-86cf-40fd7260c4bd
  const doNotResetHashRoutes = [RouteName.FORM_PRIVATE, RouteName.FORM_PUBLIC];

  if (
    workpaceCheckoutRoutes.includes(to.name as RouteName) &&
    store.getters.isAuthenticated
  ) {
    const workspaceHash = (to.hash || "").split("?")[0].replace("#", "");

    // switch to workspace from hash
    if (workspaceHash !== store.getters.activeWorkspaceId) {
      const targetWorkspaceId =
        !workspaceHash && doNotResetHashRoutes.includes(to.name as RouteName)
          ? null
          : workspaceHash;

      const workspace = store.getters.workspaces.find(
        (ws: UserWorkspace) => ws.id === targetWorkspaceId
      );

      if (workspace && workspace.type !== UserWorkspaceType.MASTER) {
        await store.dispatch(
          UserActionTypes.SET_ACTIVE_WORKSPACE_PERSIST,
          workspace
        );
      }
    }

    // set hash to url
    const workspaceId = store.getters.activeWorkspaceIdStringified;
    if (
      !doNotResetHashRoutes.includes(to.name as RouteName) &&
      workspaceHash !== workspaceId
    ) {
      router.replace({
        path: to.path,
        params: to.params,
        query: to.query,
        hash: "#" + workspaceId,
      });
      return;
    }
  }

  const permissions = RouteNamePermissions[to.name as RouteName] || [];
  if (!permissions.length) {
    next();
    return;
  }

  let isPermitted = true;
  permissions.forEach((permission) => {
    if (!isPermitted) {
      return;
    }
    isPermitted = hasUserPermission(permission);
  });

  if (!isPermitted) {
    next({ name: RouteName.DASHBOARD });
    return;
  }

  next();
};

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: RouteName.LOGIN,
    component: LogIn,
    meta: { title: "Integrate, Automate, Orchestrate " + DefaultTitleSuffix },
    beforeEnter: async (_, __, next) => {
      await ApplicationService.init();
      next();
    },
  },
  {
    path: "/create-account/:token?",
    component: CreateAccount,
  },
  {
    path: "/set-password/success",
    component: ChangePass,
    props: { mode: "SUCCESS" },
  },
  {
    path: "/set-password/:userId?",
    component: ChangePass,
  },
  {
    path: "/reset-password/:userId?",
    component: ChangePass,
    props: { state: "CHANGE" },
  },
  {
    path: "/reset-pass",
    component: ResetPass,
  },
  {
    path: "/activation",
    component: Activation,
  },
  {
    path: "/forms",
    component: Form,
    children: [
      {
        path: ":id/:workspaceId?",
        component: FormFill,
        name: RouteName.FORM_PUBLIC,
        beforeEnter: async (to: Route, from: Route, next) => {
          if (store.getters.token) {
            next({
              name: RouteName.FORM_PRIVATE,
              params: to.params,
              query: to.query,
              hash: to.hash,
            });
            return;
          }

          const workspaceId = to.params.workspaceId;
          if (!workspaceId) {
            return next();
          }

          const params = { ...to.params };
          delete params.workspaceId;

          router.replace({
            name: to.name as string,
            params,
            query: to.query,
            hash: "#" + workspaceId,
          });
        },
      },
    ],
  },
  {
    path: "/admin",
    name: RouteName.ADMIN,
    component: Admin,
    beforeEnter: async (to: Route, from: Route, next) => {
      if (!store.getters.token) {
        next(
          to.name === RouteName.FORM_PRIVATE
            ? {
                name: RouteName.FORM_PUBLIC,
                params: to.params,
                query: to.query,
                hash: to.hash,
              }
            : { name: RouteName.LOGIN }
        );
        return;
      }

      await ApplicationService.init();
      routeGuard(to, from, next);
    },
    children: [
      {
        path: "dashboard",
        component: Dashboard,
        name: RouteName.DASHBOARD,
        meta: { title: "Dashboard " + DefaultTitleSuffix },
      },
      {
        path: "credentials-manager",
        component: CredentialModule,
        meta: { title: "Credential Manager " + DefaultTitleSuffix },
        children: [
          {
            path: ":credentialId?",
            component: CredentialList,
            name: RouteName.CREDENTIAL_MANAGER,
          },
        ],
      },
      {
        path: "data-model",
        component: DataModelDesigner,
        meta: { title: "Data Model Designer " + DefaultTitleSuffix },
        children: [
          {
            path: "/",
            component: DataModelList,
            name: RouteName.DATA_MODEL_DESIGNER,
          },
          {
            path: ":modelId?",
            component: DataModelCreate,
            name: RouteName.DATA_MODEL_DESIGNER_EDIT,
            props: true,
          },
        ],
      },
      {
        path: "process",
        component: Processes,
        meta: { title: "Process Designer " + DefaultTitleSuffix },
        children: [
          {
            path: "/",
            component: ProcessList,
            name: RouteName.PROCESS_LIST,
          },
          {
            name: RouteName.PROCESS_DESIGNER,
            path: "designer/:flowId?",
            component: Designer,
            meta: {
              topbar: ProcessTopbar,
            },
          },
          {
            name: RouteName.PROCESS_INSTANCES,
            path: "instances/:flowId?",
            component: InstancesList,
            meta: {
              topbar: InstanceTopbar,
            },
          },
          {
            name: RouteName.PROCESS_LEGACY_INSTANCES,
            path: "instances/:flowId?/legacy",
            component: InstancesList,
            meta: {
              topbar: InstanceTopbar,
            },
            props: { legacy: true },
          },
          {
            path: ":flowId/details/:instanceId?",
            component: InstanceDetails,
            name: RouteName.PROCESS_DETAILS,
            meta: {
              topbar: InstanceTopbar,
            },
          },
        ],
      },
      {
        path: "document-designer",
        component: TemplateDesigner,
        meta: {
          title: RouteName.DOCUMENT_DESIGNER,
        },
        children: [
          {
            path: "/",
            name: RouteName.DOCUMENT_DESIGNER,
            component: TemplateDesignerList,
          },
          {
            path: "add",
            name: RouteName.DOCUMENT_DESIGNER_ACTION,
            component: TemplateDesignerAction,
            meta: {
              topbar: TemplateDesignerTopbar,
            },
          },
          {
            path: "edit/:id",
            name: RouteName.DOCUMENT_DESIGNER_ACTION_EDIT,
            component: TemplateDesignerAction,
            meta: {
              topbar: TemplateDesignerTopbar,
            },
          },
        ],
      },
      {
        path: "api",
        component: Api,
        meta: { title: "Api " + DefaultTitleSuffix },
        name: RouteName.API,
      },
      {
        path: "users",
        component: Users,
        meta: { title: "Users " + DefaultTitleSuffix },
        name: RouteName.USERS,
      },
      {
        path: "webhooks",
        component: Webhook,
        meta: { title: "Webhook " + DefaultTitleSuffix },
        name: RouteName.WEBHOOKS,
      },
      {
        path: "schedules",
        component: Schedule,
        meta: { title: "Schedule " + DefaultTitleSuffix },
        name: RouteName.SCHEDULES,
      },
      {
        path: "automation",
        component: Automation,
        meta: { title: "Automation " + DefaultTitleSuffix },
        name: RouteName.AUTOMATION,
      },
      {
        path: "analytics",
        component: Analytics,
        meta: { title: "Analytics " + DefaultTitleSuffix },
        children: [
          {
            path: "",
            component: ProcessAnalytics,
            name: RouteName.PROCESS_ANALYTICS,
          },
          {
            path: "instances/:flowId?",
            component: ProcessInstanceAnalytics,
            name: RouteName.PROCESS_INSTANCE_ANALYTICS,
          },
          {
            path: "instances/:flowId?/legacy",
            component: ProcessInstanceAnalytics,
            name: RouteName.PROCESS_LEGACY_INSTANCE_ANALYTICS,
            props: { legacy: true },
          },
        ],
      },
      actionDesignerRoutes,
      resourceMapRoutes,
      {
        path: "/",
        component: Administration,
        children: [
          {
            path: "workspaces",
            name: RouteName.WORKSPACES_MANAGEMENT,
            component: WorkspaceManagement,
          },
        ],
      },
      {
        path: "subscriptions",
        component: Subscription,
        meta: { title: "Subscription and billing " + DefaultTitleSuffix },
        name: RouteName.SUBSCRIPTIONS,
      },
      {
        path: "forms",
        component: Form,
        meta: { title: "Form Builder " + DefaultTitleSuffix },
        children: [
          {
            path: "/",
            component: FormList,
            name: RouteName.FORM_LIST,
          },
          {
            name: RouteName.FORM_BUILDER,
            path: "designer/:id?",
            component: FormBuilder,
            meta: {
              topbar: FormTopbar,
            },
          },
          {
            name: RouteName.FORM_INSTANCES,
            path: "submissions/:id",
            component: FormInstanceList,
            meta: {
              topbar: FormInstanceTopbar,
            },
          },
          {
            name: RouteName.FORM_INSTANCE_EDIT,
            path: "submissions/:pid/:id/edit",
            component: FormFill,
            meta: {
              topbar: FormTopbar,
            },
          },
          {
            name: RouteName.FORM_INSTANCE_DETAILS,
            path: "submissions/:id/:instanceId",
            component: FormInstanceList,
            meta: {
              topbar: FormInstanceTopbar,
            },
          },
          {
            path: ":id/:workspaceId?",
            component: FormFill,
            name: RouteName.FORM_PRIVATE,
            meta: {
              topbar: FormTopbar,
            },
            beforeEnter: async (to: Route, from: Route, next) => {
              const workspaceId = to.params.workspaceId;
              if (!workspaceId) {
                return next();
              }

              const params = { ...to.params };
              delete params.workspaceId;

              router.replace({
                name: to.name as string,
                params,
                query: to.query,
                hash: "#" + workspaceId,
              });
            },
          },
        ],
      },
      {
        path: "form-assignments",
        component: Form,
        meta: { title: "Form Assignments " + DefaultTitleSuffix },
        children: [
          {
            path: "/",
            component: FormAssignmentList,
            name: RouteName.FORM_ASSIGNMENTS,
          },
          {
            path: ":id",
            component: FormFill,
            name: RouteName.FORM_ASSIGNMENT_REVIEW,
            meta: {
              topbar: FormTopbar,
            },
          },
        ],
      },
    ],
  },
  // 404
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  // routes backward compatibility
  const hashTagPart = "/#/";
  if (to.fullPath.includes(hashTagPart)) {
    next(to.fullPath.replace(hashTagPart, "/"));
    return;
  }

  setTitle(getTitleByRoute(to));
  if (!!from.name) {
    return routeGuard(to, from, next);
  }

  return next();
});

export default router;
