import { RouteConfig } from "vue-router";

const ActionDesignerLayout = () =>
  import(
    "@/modules/CustomActionDesigner/presentation/CustomActionDesigner.layout.vue"
  );

const ActionDesignerPage = () =>
  import(
    "@/modules/CustomActionDesigner/presentation/pages/ActionDesigner/ActionDesigner.component.vue"
  );

const ActionDesignerTopbar = () =>
  import(
    "@/modules/CustomActionDesigner/presentation/components/Topbar/ActionDesignerTopbar.component.vue"
  );

export enum RouteName {
  CUSTOM_ACTION_DESIGNER = "Action Designer",
}

export const routes: RouteConfig = {
  path: "action-designer",
  component: ActionDesignerLayout,
  name: RouteName.CUSTOM_ACTION_DESIGNER,
  meta: { title: RouteName.CUSTOM_ACTION_DESIGNER },
  children: [
    {
      path: "/",
      component: ActionDesignerPage,
      name: RouteName.CUSTOM_ACTION_DESIGNER,
      meta: {
        topbar: ActionDesignerTopbar
      }
    },
  ],
};
