<template>
  <div
    :class="[
      'pup-c-editor--container',
      isFullScreen && 'pup-c-editor--container--full-screen',
    ]"
    ref="container"
  >
    <pds-control-label
      :label="label"
      :tooltip="tooltip"
      :required="required"
      :ignoreFormatting="ignoreLabelFormatting"
      v-if="!isFullScreen"
    />
    <div
      v-if="isFullScreen"
      class="pup-c-editor--full-screen-slot"
      key="full-screen"
    >
      <div class="pup-c-editor--header">
        <span class="pup-c-editor--header--title">
          {{
            fullScreenTitle ||
              (parent ? parent.name : "") + (label ? " | " + label : "")
          }}
        </span>
        <pds-icon
          class="pup-c-editor--header--close"
          icon="close"
          @click="toggleFullScreen(false)"
        />
      </div>
      <slot name="fullScreenEditorPrepend" />
    </div>

    <div
      ref="editor"
      :class="[
        'pup-c-editor',
        disabled ? 'pup-c-editor--disabled' : '',
        isFullScreen ? 'pup-c-editor--full-screen' : '',
      ]"
      @keydown="onEditorKeyDown"
    />
    <pds-message
      v-if="messageText"
      size="small"
      icon="info"
      :class="[
        'pup-c-editor--info pds-u-p--r--12 pds-u-m--t--8',
        isFullScreen && 'pds-u-m--l--24 pds-u-m--b--12',
      ]"
      variation="ghost"
      :text="messageText"
    />

    <pds-validation-message
      v-if="!isFullScreen"
      :status="status"
      @statusClassChanged="(e) => (statusClass = e)"
    />
    <pup-data-model-selector
      @closed="closeDataModelSelector"
      v-if="isDataModelSelectorVisible && !disabled"
      :parentId="parent ? parent.parentId : null"
      :dataModelList="[]"
      :class="[
        'pup-c-input-data-model-selector--acordeon',
        'pup-c-input-data-model-selector--acordeon--right',
      ]"
      :style="{
        top: `${editorOffsetTop}px`,
      }"
      :parentDataModelId="parentDataModelId"
      :parentVariableId="parentVariableId"
      :processVariables="processVariables"
      :expectedDataModelId="settings ? settings.dataTypeId : null"
      :isListExpected="settings ? settings.isList : false"
      @pickElement="selectVariable"
    />
    <pds-icon
      v-if="typeof isInputValid === 'boolean'"
      :icon="isInputValid ? 'check' : 'clear'"
      :isOutlined="true"
      v-tooltip="{
        content: isInputValid ? 'Valid' : 'Invalid',
        boundariesElement: 'window',
      }"
      size="tiny"
      :style="{
        top: `${editorOffsetTop}px`,
        right: `${editorMinimapWidth + 12}px`,
      }"
      :class="[
        'pup-c-input-group--validity-status--editor',
        'pup-c-input-group--validity-status--' +
          (isInputValid ? 'valid' : 'invalid'),
      ]"
    />
  </div>
</template>

<script lang="ts">
import { mixins } from "vue-class-component";

import { Prop, Component, Model, Watch } from "vue-property-decorator";

import * as monaco from "monaco-editor";

import {
  IconComponent,
  ValidationMessage,
  ControlLabelComponent,
  MessageComponent,
  PdsTypes,
} from "@procesio/procesio-design-system";

import { ControlsBus } from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.component.vue";
import DataModelSelector from "@/modules/ProcessDesigner/components/DataModelSelector/DataModelSelector.component.vue";
import { VariableParser } from "@/modules/ProcessDesigner/Variables/Utils/VariableParser";
import { ProcessVariable } from "@/services/processvariables/ProcessVariables.model";
import { Variable } from "@/modules/ProcessDesigner/Variables/Utils/Variable";
import { EventBus, Events } from "@/utils/eventBus";
import { mapGetters } from "vuex";
import {
  isHotKeyMatched,
  HotKeys,
  hotKeyToString,
} from "@/utils/keyboard/hotkeys";
import { ActionTypes as UIActionTypes } from "@/store/ui/UI.actions";
import { getKeyboardEventCode } from "@/utils/keyboard";
import {
  Setting,
  Node,
} from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import {
  DataModel,
  isCustomDataModel,
} from "@/services/datamodel/DataModel.model";
import { EditorLanguage } from "@/modules/ProcessDesigner/components/CodeEditors/Editor/Editor.model";
import { SQL_RESERVED_WORDS } from "@/utils/type/sql";

@Component({
  components: {
    "pup-data-model-selector": DataModelSelector,
    "pds-icon": IconComponent,
    "pds-validation-message": ValidationMessage,
    "pds-control-label": ControlLabelComponent,
    "pds-message": MessageComponent,
  },
  computed: {
    ...mapGetters({
      keyboardPressedCodes: "keyboardPressedCodes",
    }),
  },
  name: "pup-editor",
})
export default class EditorComponent extends mixins(VariableParser, Variable) {
  @Prop() settings!: Setting;

  @Prop() tooltip?: string;

  @Prop() parent!: Node;

  @Prop() status!: PdsTypes.InputStatus;

  @Prop() processVariables!: ProcessVariable[];

  @Model("update-input") value!: string;
  // value =
  //   "94cc1fd3-be4c-4231-a1c6-39ea28ff554a.fe9fef55-2f38-42f2-8a0c-7b4e334e6622.c4024395-1a1b-4680-8bd2-9b65f85e1566 asd 2d7d0370-b2e0-40eb-acba-d75526823944";

  @Prop({ default: "" }) label!: string;

  @Prop({ default: EditorLanguage.TEXT }) lang!: EditorLanguage | null;

  @Prop({ default: false }) required!: boolean;

  @Prop({ default: null }) isInputValid!: boolean | null;

  @Prop({ default: false, type: Boolean }) readonly!: boolean;

  // disabled is the same as "readonly" but the appearance is changed
  @Prop({ default: false, type: Boolean }) disabled!: boolean;

  @Prop({ default: true, type: Boolean }) hasDataModelSelector!: boolean;

  @Prop({ default: true, type: Boolean }) hasFullScreenMode!: boolean;

  @Prop({ default: "" }) fullScreenTitle!: string;

  @Prop({ default: false, type: Boolean }) autoformat!: boolean;

  @Prop({ default: false, type: Boolean }) ignoreLabelFormatting!: boolean;

  @Prop({ default: undefined, type: Boolean }) fullScreen!: boolean; // force full screen state

  @Prop({ default: null }) parentDataModelId!: string | null;

  @Prop({ default: null }) parentVariableId!: string | null;

  isDataModelSelectorVisible = false;

  mEdit!: monaco.editor.IStandaloneCodeEditor;

  formatDocumentAction: monaco.editor.IEditorAction | null = null;

  lineNumber = 0;

  column = 0;

  // regex should consider space at the beginning
  guidRegex = /\s([0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\.[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12})*)/gm;

  statusClass = "";

  isReplaceableValue = false;

  isFullScreen = false;

  isFormatting = false;

  formatDocumentContexMenuLabel = "Format Document";

  editorOffsetTop = 0;

  editorMinimapWidth = 0;

  variableDecorations: string[] = [];

  sqlCompletionProvider: monaco.IDisposable | null = null;

  get messageText() {
    const messages = [];
    if (this.hasDataModelSelector && !this.disabled) {
      messages.push(
        `To open variable selector press ${hotKeyToString(
          HotKeys.TOGGLE_DATA_MODEL_SELECTOR
        )}.`
      );
    }

    if (this.hasFullScreenMode) {
      if (this.isFullScreen) {
        messages.push(
          `To collapse input box press ${hotKeyToString(HotKeys.COLLAPSE_INPUT)}.`
        );
      } else {
        messages.push(
          `To enlarge input box press ${hotKeyToString(HotKeys.ENLARGE_INPUT)}.`
        );
      }
    }

    return messages.join("<br>");
  }

  get hasAnyVariable() {
    if (!this.hasDataModelSelector) {
      return false;
    }

    return (
      this.value &&
      (!!this.hasVariable(this.value) ||
        !!this.hasPlaceholder(this.value) ||
        this.value.includes("<%%>"))
    );
  }

  @Watch("hasAnyVariable")
  onHasAnyVariableUpdate() {
    this.onLanguageUpdate(this.lang);
  }

  @Watch("value", { immediate: true, deep: true })
  valueChangeHandler(value: string | boolean | null) {
    if (value !== null) {
      if (typeof value === "string") {
        // add temp space before each variable start to avoid variables concatenation
        (this.processVariables || []).forEach((variable) => {
          value = (value as string).replaceAll(variable.id, " " + variable.id);
        });

        this.internalValue = value
          .replaceAll(this.guidRegex, (match) => {
            // use trim() to clear temp space
            return this.parseVariableIdToName(
              match.trim(),
              true,
              this.processVariables
            );
          })
          .replaceAll(this.placeholderRegex, () => {
            return "<%%>";
          });
      }
      // check for number | boolean
      else if (!isNaN(value as any)) {
        this.internalValue = value.toString();
      }
    } else {
      this.internalValue = "";
    }

    this.formatEditor();
  }

  internalValue = "";
  @Watch("internalValue", { immediate: true, deep: true })
  internValueChangeHandler(value: string, previous: string | undefined) {
    // reset value
    if (
      !this.isFormatting &&
      this.mEdit &&
      this.mEdit.getModel() &&
      previous &&
      (!value || !this.mEdit.hasTextFocus())
    ) {
      console.log("update", value);
      this.mEdit.getModel()?.setValue(value);
    }
  }

  @Watch("readonly")
  onReadonlyUpdate(readonly: boolean) {
    if (!this.mEdit) {
      return;
    }

    this.mEdit.updateOptions({ readOnly: readonly });
  }

  @Watch("disabled")
  onDisabledUpdate(isDisabled: boolean) {
    if (!this.mEdit) {
      return;
    }

    this.mEdit.updateOptions({
      readOnly: isDisabled || this.readonly,
      theme: "PROCESIO" + (isDisabled ? "DISABLED" : ""),
    });
  }

  @Watch("lang")
  onLanguageUpdate(language: EditorLanguage | null) {
    const model = this.mEdit?.getModel();
    if (!model) {
      return;
    }

    this.$nextTick(() => {
      if (
        !language ||
        !Object.values(EditorLanguage).includes(language) ||
        this.hasAnyVariable
      ) {
        language = EditorLanguage.TEXT;
      }

      monaco.editor.setModelLanguage(model, language);

      this.formatEditor();

      // needed for action template placeholders (<%%>)
      const legend: monaco.languages.SemanticTokensLegend = {
        tokenTypes: ["<%%>"],
        tokenModifiers: [],
      };
      monaco.languages.registerDocumentSemanticTokensProvider(language, {
        getLegend: function() {
          return legend;
        },
        releaseDocumentSemanticTokens: function(resultId) {
          console.log(resultId);
        },
        provideDocumentSemanticTokens: function(model) {
          const getModifier = (modifiers: string[]) => {
            if (typeof modifiers === "string") {
              modifiers = [modifiers];
            }

            if (Array.isArray(modifiers)) {
              let nModifiers = 0;
              for (const modifier of modifiers) {
                const nModifier = legend.tokenModifiers.indexOf(modifier);
                if (nModifier > -1) {
                  nModifiers |= (1 << nModifier) >>> 0;
                }
              }
              return nModifiers;
            } else {
              return 0;
            }
          };

          const getType = (type: string) => legend.tokenTypes.indexOf(type);

          const tokenPattern = new RegExp(
            "([a-zA-Z<%%>]+)((?:\\.[a-zA-Z]+)*)",
            "g"
          );

          const lines = model.getLinesContent();

          /** @type {number[]} */
          const data = [];

          let prevLine = 0;
          let prevChar = 0;

          for (let i = 0; i < lines.length; i++) {
            const line = lines[i];

            for (let match = null; (match = tokenPattern.exec(line)); ) {
              // translate token and modifiers to number representations
              const type = getType(match[1]);
              if (type === -1) {
                continue;
              }
              const modifier = match[2].length
                ? getModifier(match[2].split(".").slice(1))
                : 0;

              data.push(
                // translate line to deltaLine
                i - prevLine,
                // for the same line, translate start to deltaStart
                prevLine === i ? match.index - prevChar : match.index,
                match[0].length,
                type,
                modifier
              );

              prevLine = i;
              prevChar = match.index;
            }
          }
          return {
            data: new Uint32Array(data),
            resultId: undefined,
          };
        },
      });
    });
  }

  @Watch("isFullScreen")
  onFullScreenStateUpdate(isFullScreen: boolean) {
    EventBus.$emit(
      Events["INPUT:FULLSCREEN_TOGGLE"],
      isFullScreen,
      this.settings?.id || null
    );

    this.$nextTick(() => {
      let top = 0;
      if (isFullScreen) {
        top =
          this.$refs.editor.getBoundingClientRect().top -
          this.$refs.container.getBoundingClientRect().top;
      }
      this.editorOffsetTop = top;

      setTimeout(() => {
        this.onResize();
      }, 0);
    });
  }

  @Watch("fullScreen", { immediate: true })
  onForcedFullScreenUpdate(fullScreen: boolean | undefined) {
    if (fullScreen !== undefined) {
      this.toggleFullScreen(fullScreen);

      if (fullScreen) {
        this.$nextTick(() => {
          if (this.mEdit) {
            this.mEdit.focus();
            this.mEdit.setPosition({
              lineNumber: this.mEdit.getModel().getLineCount(),
              column: 1000000000, // did not find a way to get column count :(
            });
          }
        });
      }
    }
  }

  @Watch("formatDocumentAction")
  onFormatDocumentActionInit(
    action: monaco.editor.IEditorAction | null,
    old: monaco.editor.IEditorAction | null
  ) {
    if (!old) {
      this.formatEditor();
    }
  }

  formatEditor(forceFormat = false) {
    const model = this.mEdit?.getModel();

    if (
      !this.formatDocumentAction ||
      this.isFormatting ||
      !this.autoformat ||
      !model ||
      (this.mEdit.hasTextFocus() && !forceFormat)
    ) {
      return;
    }

    const language = (model as any)._languageIdentifier.language;

    this.isFormatting = true;

    // use html formatted because monaco does not have xml formatter by default
    if (language === EditorLanguage.XML) {
      monaco.editor.setModelLanguage(model, EditorLanguage.HTML);
    }

    // turn off readonly mode to format
    if (this.readonly) {
      this.onReadonlyUpdate(false);
    }

    this.formatDocumentAction
      .run()
      .then(() => {
        // revert xml language after formatting
        if (language === EditorLanguage.XML) {
          monaco.editor.setModelLanguage(model, EditorLanguage.XML);
        }

        // remove spaces between variable characters after formatting (e.g. javascript formatter adds the spaces)
        const value = model
          .getValue(monaco.editor.EndOfLinePreference.CRLF)
          .replaceAll("<% ", "<%")
          .replaceAll(" %>", "%>");

        this.isFormatting = false;
        this.internalValue = value;
        model.setValue(value);

        this.mEdit.focus();
        this.mEdit.setPosition({
          lineNumber: this.lineNumber,
          column: this.column,
        });
      })
      .finally(() => {
        // turn on readonly mode back as it is needed
        if (this.readonly) {
          this.onReadonlyUpdate(true);
        }
      });
  }

  // handle keydown separately to override monaco hotkeys
  async onEditorKeyDown(event: KeyboardEvent) {
    await this.$store.dispatch(
      UIActionTypes.ADD_PRESSED_KEYBOARD_CODE,
      getKeyboardEventCode(event)
    );

    if (
      !this.isFullScreen &&
      this.hasFullScreenMode &&
      isHotKeyMatched(HotKeys.ENLARGE_INPUT, this.keyboardPressedCodes)
    ) {
      event.preventDefault();
      event.stopPropagation();
      this.toggleFullScreen(true);

      this.$nextTick(() => {
        this.mEdit.focus();
      });
    } else if (
      this.isFullScreen &&
      this.hasFullScreenMode &&
      isHotKeyMatched(HotKeys.COLLAPSE_INPUT, this.keyboardPressedCodes)
    ) {
      event.preventDefault();
      event.stopPropagation();
      this.toggleFullScreen(false);

      this.$nextTick(() => {
        this.mEdit.focus();
      });
    } else if (
      this.hasDataModelSelector &&
      isHotKeyMatched(
        HotKeys.TOGGLE_DATA_MODEL_SELECTOR,
        this.keyboardPressedCodes
      )
    ) {
      event.preventDefault();
      event.stopPropagation();
      this.openSelector();
    } else if (
      isHotKeyMatched(HotKeys.FORMAT_EDITOR_CONTENT, this.keyboardPressedCodes)
    ) {
      event.preventDefault();
      event.stopPropagation();
      this.formatEditor(true);
    }
  }

  mounted() {
    this.sqlCompletionProvider = monaco.languages.registerCompletionItemProvider(
      "sql",
      {
        provideCompletionItems: () => {
          return {
            suggestions: SQL_RESERVED_WORDS.map((word) => ({
              label: word,
              kind: monaco.languages.CompletionItemKind.Text,
              insertText: word,
            })),
          };
        },
      }
    );

    monaco.editor.defineTheme("PROCESIO", {
      base: "vs",
      inherit: true,
      rules: [{ token: "<%%>", foreground: "D11C2C", fontStyle: "bold" }],
      colors: {},
    });

    monaco.editor.defineTheme("PROCESIODISABLED", {
      base: "vs",
      inherit: true,
      rules: [
        {
          token: "<%%>",
          fontStyle: "bold",
          foreground: "f7f9fc",
          background: "f7f9fc",
        },
      ],
      colors: {
        "editor.background": "#f7f9fc",
        "editorRuler.foreground": "#f7f9fc",
        "minimap.background": "#f7f9fc",
        "editorLineNumber.foreground": "#8D9BB5",
      },
    });

    const editorRef = this.$refs["editor"];
    const textModel = monaco.editor.createModel(this.internalValue);
    textModel.pushEOL(monaco.editor.EndOfLineSequence.CRLF);
    this.mEdit = monaco.editor.create(editorRef as HTMLElement, {
      model: textModel,
      theme: "PROCESIO",
      "semanticHighlighting.enabled": true,
      automaticLayout: true,
    });

    const formatDocumentAction = this.mEdit.getAction(
      "editor.action.formatDocument"
    );
    // added timeout to get action really loaded because it does not work immediatelly;
    // todo: find a way to preload the action
    setTimeout(() => {
      this.formatDocumentAction = formatDocumentAction;
    }, 200);

    if (this.hasDataModelSelector) {
      this.mEdit.onMouseUp((e) => {
        const { lineNumber, column } = e.target.position as monaco.Position;

        this.lineNumber = lineNumber;
        this.column = column;

        const valueByLine = this.mEdit
          .getModel()
          ?.getLineContent(this.lineNumber);

        if (
          valueByLine?.charAt(this.column - 3) === "<" &&
          valueByLine?.charAt(this.column - 2) === "%" &&
          valueByLine?.charAt(this.column - 1) === "%" &&
          valueByLine?.charAt(this.column) === ">"
        ) {
          setTimeout(() => {
            this.isReplaceableValue = true;
            this.isDataModelSelectorVisible = true;
          }, 300);
        }
      });
    }

    this.mEdit.onDidChangeModelContent((e: any) => {
      if (!e.isFlush) {
        const position = this.mEdit.getPosition();
        this.lineNumber = position?.lineNumber as number;
        this.column = position?.column as number;
        this.emitChange(this.mEdit.getValue());
        this.$nextTick(() => this.addDecorations());
      } else {
        if (this.mEdit.getValue() !== this.internalValue) {
          this.mEdit.getModel()?.setValue(this.internalValue);
        }
        this.addDecorations();
      }
    });

    this.mEdit.onDidBlurEditorWidget((e: FocusEvent | null = null) =>
      this.$emit("blur", e)
    );

    this.mEdit.onDidPaste((event) => {
      this.lineNumber = event.range.endLineNumber;
      this.column = event.range.endColumn;

      this.formatEditor(true);
    });

    // hide it since we use custom hotkeys
    // this.mEdit.addAction({
    //   id: "procesio-format-document",
    //   label: this.formatDocumentContexMenuLabel + " ", // add space to customize label and not remove it from context menu
    //   keybindings: [
    //     monaco.KeyMod.Shift | monaco.KeyMod.Alt | monaco.KeyCode.KEY_F,
    //   ],
    //   contextMenuGroupId: "1_modification",
    //   contextMenuOrder: 4,
    //   run: () => this.formatEditor(true),
    // });

    this.mEdit.onContextMenu(() => {
      const host = document.querySelector(".shadow-root-host");
      const root = host?.shadowRoot;

      if (!root) {
        return;
      }

      root.querySelectorAll(".action-item").forEach((actionItem) => {
        // hide default format document button from context menu
        if (
          actionItem.querySelector(
            `[aria-label="${this.formatDocumentContexMenuLabel}"]`
          )
        ) {
          (actionItem as HTMLElement).style.display = "none";
        }
      });
    });

    this.onReadonlyUpdate(this.readonly);
    this.onDisabledUpdate(this.disabled);
    this.onLanguageUpdate(this.lang as EditorLanguage);
    this.addDecorations();

    this.$nextTick(() => {
      this.onResize();
    });
    window.addEventListener("resize", this.onResize);
  }

  onResize() {
    if (this.$refs.editor) {
      const minimap = (this.$refs.editor as HTMLElement).querySelector(
        ".minimap"
      );
      if (minimap) {
        const style = window.getComputedStyle(minimap);
        const width = parseInt(style.getPropertyValue("width"), 10);
        this.editorMinimapWidth = width;
      }
    }
  }

  // onFullScreenToggle(_: boolean, settingId: string | null) {
  //   console.log('onFullScreenToggle', this.isFullScreen, this.settings, settingId)
  //   if (this.isFullScreen && this.settings && !settingId) {
  //     this.toggleFullScreen(false);
  //   }
  // }

  addDecorations() {
    if (!this.value) {
      return;
    }
    const decorations: any[] = [];

    this.value.split("\n").forEach((row: string, rowIndex: number) => {
      // add temp space before each variable start to avoid variables concatenation
      (this.processVariables || []).forEach((variable) => {
        row = (row as string).replaceAll(variable.id, " " + variable.id);
      });

      const matches = [...row.matchAll(this.guidRegex)];

      let indexOffset = 0;
      matches.forEach((match) => {
        if (match) {
          const guid = match[0];
          // use trim() to clear temp space
          const parsedVariable = this.parseVariableIdToName(
            guid.trim(),
            true,
            this.processVariables
          );

          if (parsedVariable) {
            // use trim() to clear temp space
            const dataType = this.getVariableDataType(
              guid.trim(),
              this.processVariables
            );

            const dataTypeKey =
              dataType && !isCustomDataModel(dataType as DataModel)
                ? dataType?.displayName.toLowerCase()
                : null;

            let dataTypeTooltip = null;

            if (dataType) {
              // use trim() to clear temp space
              const isList = this.isVariableList(
                guid.trim(),
                this.processVariables
              );

              dataTypeTooltip = `${dataType.isProcesio ? "" : "Data model:"} ${
                isList ? "list &lt;" : ""
              }${dataType?.displayName}${isList ? "&gt;" : ""}`;
            }

            // add 1 to row and column beecause they start from 1 for Monaco Range
            decorations.push({
              range: new monaco.Range(
                rowIndex + 1,
                match.index + 1 - indexOffset,
                rowIndex + 1,
                match.index + 1 + parsedVariable.length - indexOffset
              ),
              options: {
                className: `lineDecoration ${dataTypeKey &&
                  "lineDecoration--" + dataTypeKey}`,
                inlineClassName: `inlineDecoration ${dataTypeKey &&
                  "inlineDecoration--" + dataTypeKey}`,
                hoverMessage: {
                  value: dataTypeTooltip,
                },
              },
            });

            indexOffset += guid.length - parsedVariable.length;
          }
        }
      });
    });

    this.variableDecorations = this.mEdit.deltaDecorations(
      this.variableDecorations,
      decorations
    );
  }

  toggleFullScreen(isFullScreen: boolean) {
    this.isFullScreen = isFullScreen;
  }

  beforeDestroy() {
    EventBus.$emit(
      Events["INPUT:FULLSCREEN_TOGGLE"],
      false,
      this.settings?.id || null
    );

    window.removeEventListener("resize", this.onResize);

    if (this.sqlCompletionProvider) {
      this.sqlCompletionProvider.dispose();
    }
    // EventBus.$off(Events["INPUT:FULLSCREEN_TOGGLE"], this.onFullScreenToggle);
  }

  emitChange(value: string) {
    if (this.isFormatting) {
      return;
    }
    const parsedValue = this.parseVariableNameToId(
      value,
      this.processVariables
    );
    ControlsBus.$emit("change:editor", parsedValue);
    this.$emit("update-input", parsedValue);
  }

  closeDataModelSelector(event: Event | null = null) {
    this.isDataModelSelectorVisible = false;
    if ((event as KeyboardEvent)?.code === "Escape" && this.mEdit) {
      this.mEdit.focus();
    }
  }

  selectVariable(variable: string) {
    // inset variable at caret position
    this.mEdit.executeEdits("", [
      {
        range: new monaco.Range(
          this.lineNumber,
          this.column,
          this.lineNumber,
          this.column
        ),
        text: this.parseVariableIdToName(
          variable,
          !this.isReplaceableValue,
          this.processVariables
        ),
      },
    ]);

    this.isDataModelSelectorVisible = false;
    this.isReplaceableValue = false;

    // refocus the edior
    this.mEdit.focus();

    // set the caret position after the new insertion
    this.mEdit.setPosition({
      lineNumber: this.lineNumber,
      column: this.column + variable.length + 1,
    });

    // scroll left to the new caret position
    this.mEdit.setScrollPosition({
      scrollLeft: this.column + variable.length + 1,
    });
  }

  openSelector() {
    const position = this.mEdit.getPosition();
    this.lineNumber = position?.lineNumber as number;
    this.column = position?.column as number;
    this.isDataModelSelectorVisible = true;
  }
}
</script>

<style lang="scss">
.pup-c-editor {
  &--disabled {
    .monaco-editor {
      .view-line .mtk1 {
        color: #8d9bb5 !important;
      }
    }
  }

  &--container {
    position: relative;
    display: flex;
    flex-direction: column;

    &--full-screen {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background-color: white;
    }
  }

  $header-height: 64px;

  width: 100%;
  height: 280px;

  &--full-screen {
    flex: 1;
    margin-top: 16px;
  }

  &--full-screen-slot {
    min-height: #{$header-height};
  }

  &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    width: 100%;
    height: $header-height;
    z-index: 1;
    background-color: white;
    border-bottom: 1px solid var(--c-neutral--300);

    &--title {
      font-size: 18px;
    }

    &--close {
      color: var(--c-neutral--500);
      cursor: pointer;
    }
  }
}

.lineDecoration {
  border-radius: 0.25rem;
  background: #ebfffd;

  &--boolean {
    background: #f4effd;
  }

  &--string {
    background: #ebeef2;
  }

  &--integer {
    background: #ebeffd;
  }

  &--float,
  &--double {
    background: #f0f7ed;
  }

  &--time,
  &--date,
  &--datetime {
    background: #fff6eb;
  }

  &--file {
    background: #ebf4fc;
  }

  &--guid {
    background: #fbf4f0;
  }
}

.inlineDecoration {
  font-weight: bold;

  color: var(--c-accent-2--700) !important;

  &--boolean {
    color: var(--c-accent-3) !important;
  }

  &--string {
    color: var(--c-primary--700) !important;
  }

  &--integer {
    color: var(--c-accent-1) !important;
  }

  &--float,
  &--double {
    color: var(--c-success--800) !important;
  }

  &--time,
  &--date,
  &--datetime {
    color: var(--c-warning) !important;
  }

  &--file {
    color: var(--c-info) !important;
  }

  &--guid {
    color: var(--c-warning--800) !important;
  }
}
</style>
