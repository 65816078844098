import { BannerType, BannerUnit } from "@/components/Banner/banner.model";
import ls from "@/services/localStorage";
import { ResourceTime } from "@/services/resources/Resource.model";
import ResourceService from "@/services/resources/Resource.service";
import { getIntervalFilterValue } from "@/utils/type/time";
import { Commit, Dispatch } from "vuex";
import { MutationTypes } from "./Resource.mutations";
import { ActionTypes as UIActionTypes } from "../ui/UI.actions";
import { LsMessageKey } from "@/store/ui/UI.store";

export enum ActionTypes {
  LOAD_TIME = "LOAD_TIME",
  SET_TIME = "SET_TIME",
  SET_EXPIRATION_DATE = "SET_EXPIRATION_DATE",
  SET_AUTO_RENEWABLE = "SET_AUTO_RENEWABLE",
}

export interface Actions {
  [ActionTypes.LOAD_TIME]({ dispatch }: { dispatch: Dispatch }): void;

  [ActionTypes.SET_TIME](
    {
      commit,
      dispatch,
      getters,
    }: { commit: Commit; dispatch: Dispatch; getters: any },
    payload: ResourceTime[]
  ): void;

  [ActionTypes.SET_EXPIRATION_DATE](
    {
      commit,
      dispatch,
      getters,
    }: { commit: Commit; dispatch: Dispatch; getters: any },
    payload: Date
  ): void;

  [ActionTypes.SET_AUTO_RENEWABLE](
    { commit }: { commit: Commit },
    payload: boolean
  ): void;
}

export const actions: any = {
  async [ActionTypes.LOAD_TIME]({ dispatch }: { dispatch: Dispatch }) {
    const data = await ResourceService.getCurrentPlanResources(
      getIntervalFilterValue()
    );

    if (data.content && !data.is_error) {
      await dispatch(ActionTypes.SET_TIME, data.content.time);
      await dispatch(
        ActionTypes.SET_AUTO_RENEWABLE,
        data.content.autoRenewable
      );
      await dispatch(
        ActionTypes.SET_EXPIRATION_DATE,
        data.content.expirationDate
      );
    }
  },

  [ActionTypes.SET_TIME](
    {
      commit,
      dispatch,
      getters,
    }: { commit: Commit; dispatch: Dispatch; getters: any },
    time: ResourceTime
  ) {
    commit(MutationTypes.SET_TIME, time);

    let bannerType: BannerType | null = null;
    if (time && time.limit.soft > 0) {
      if (time.limit.hard > 0) {
        if (
          time.masterConsumed >= time.notifyThreshold &&
          time.masterConsumed < time.limit.soft
        ) {
          bannerType = BannerType["RESOURCE:SOFT_LIMIT_END_SOON"];
        } else if (time.masterConsumed >= time.limit.soft) {
          bannerType =
            time.masterConsumed <= time.limit.soft + time.limit.hard &&
            time.canExceedPaidTime
              ? BannerType["RESOURCE:SOFT_LIMIT_REACHED"]
              : BannerType["RESOURCE:LIMIT_REACHED"];
        }
      } else {
        if (time.masterConsumed >= time.limit.soft) {
          bannerType = BannerType["RESOURCE:LIMIT_REACHED"];
        }
      }
    }

    const messages = ls.get(LsMessageKey) || {};
    const workspaceMessages = messages[getters.activeWorkspaceIdStringified];
    // new banner type - clear local storage
    if (
      workspaceMessages &&
      workspaceMessages[BannerUnit.RESOURCE] !== bannerType
    ) {
      // delete workspaceMessages[BannerUnit.RESOURCE];
      ls.set(LsMessageKey, messages);
    }

    dispatch(UIActionTypes.SET_BANNER_TYPE, {
      key: BannerUnit.RESOURCE,
      value: bannerType,
    });
  },

  [ActionTypes.SET_EXPIRATION_DATE](
    {
      commit,
      dispatch,
      getters,
    }: { commit: Commit; dispatch: Dispatch; getters: any },
    date: Date
  ) {
    commit(MutationTypes.SET_EXPIRATION_DATE, date);

    let bannerType: BannerType | null = null;

    if (date && !getters.autoRenewable) {
      date = new Date(date);
      const now = new Date();
      const nowPlus30Days = new Date(now);
      nowPlus30Days.setDate(nowPlus30Days.getDate() + 30);

      if (date < now) {
        bannerType = BannerType["SUBSCRIPTION:EXPIRED"];
      } else if (date < nowPlus30Days) {
        bannerType = BannerType["SUBSCRIPTION:EXPIRE_SOON"];
      }
    }

    const messages = ls.get(LsMessageKey) || {};
    const workspaceMessages = messages[getters.activeWorkspaceIdStringified];
    // new banner type - clear local storage
    if (
      workspaceMessages &&
      workspaceMessages[BannerUnit.SUBSCRIPTION] !== bannerType
    ) {
      delete workspaceMessages[BannerUnit.SUBSCRIPTION];
      ls.set(LsMessageKey, messages);
    }

    dispatch(UIActionTypes.SET_BANNER_TYPE, {
      key: BannerUnit.SUBSCRIPTION,
      value: bannerType,
    });
  },

  [ActionTypes.SET_AUTO_RENEWABLE](
    { commit }: { commit: Commit },
    payload: boolean
  ) {
    commit(MutationTypes.SET_AUTO_RENEWABLE, payload);
  },
};
