import RestService from "@/services/Rest.service";
import RestUrls from "@/services/Rest.paths";
import { store } from "@/store";

export default class FileService {
  static async getFile(
    header: {
      uploadFilePath: string;
      flowTemplateId?: string;
      instanceId?: string;
      variableId?: string;
      workspaceId?: string | undefined;
    },
  ) {
    const request = (isArchived: boolean) =>
      RestService.get<File>({
        url: RestUrls.API.FILE.DOWNLOAD,
        isAuth: !!store.getters.token,
        workspaced: !!store.getters.token,
        header: header,
        queryParams: { isArchived },
      });

    const nonArchivedInstanceFileResponse = await request(false);

    return nonArchivedInstanceFileResponse.is_error
      ? request(true)
      : nonArchivedInstanceFileResponse;
  }
}
